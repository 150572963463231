import React from 'react'
import { FaCaretDown } from 'react-icons/fa'

import {
  chakraComponents,
  DropdownIndicatorProps,
  GroupBase,
} from 'chakra-react-select'

import { Icon } from '@chakra-ui/react'

export const DropdownIndicator: React.FC<
  DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
> = props => {
  return (
    <chakraComponents.DropdownIndicator {...props}>
      <Icon as={FaCaretDown} boxSize={4} />
    </chakraComponents.DropdownIndicator>
  )
}
