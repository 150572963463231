import { IFilter } from '@/components/DataFilter/types'

export const ACTIVE = {
  value: 'active',
  label: 'Ativo',
}

export const INACTIVE = {
  value: 'inactive',
  label: 'Inativo',
}

export const loremIpsum = `Mussum Ipsum, cacilds Vidis litro abertis. Näo sou faixa
                      preta cumpadi, sou preto inteiris, inteiris. A ordem dos
                      tratores näo altera o päo duris. Nec orci ornare
                      consequat. Praesent lacinia ultrices consectetur.`

export const tabItemsPermissions = [
  {
    id: 'usuarios',
    label: 'Usuários',
    permissions: [
      {
        key: 'vincular-desvincular',
        title: 'Vincular e desvincular usuários',
        description: loremIpsum,
      },
      {
        key: 'perfil',
        title: 'Perfil de usuário',
        description: loremIpsum,
      },
    ],
  },
  {
    id: 'equipes',
    label: 'Equipes',
  },
  {
    id: 'permissions',
    label: 'Permissões',
  },
  {
    id: 'objetos',
    label: 'Objetos',
  },
  {
    id: 'formularios',
    label: 'Formulários',
  },
  {
    id: 'digiligencias',
    label: 'Diligências',
  },
]

export const permissionsMap = {
  U: 'Usuários',
  E: 'Equipes',
  P: 'Permissões',
  O: 'Objetos',
  F: 'Formulários',
}

export const statusFilter: Array<IFilter> = [
  {
    name: 'filter1',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'Ativo', value: 'ativo' },
      { label: 'Inativo', value: 'Inativo' },
    ],
  },
]
