import { ChakraStylesConfig } from 'chakra-react-select'

const reactSelectStyles = (): ChakraStylesConfig => ({
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    background: 'transparent',
    px: 3,
    w: '2.5rem',
    color: 'brand.primary.dark_1',
  }),
})

export default reactSelectStyles
