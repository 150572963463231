import React from 'react'

import { Stack } from '@chakra-ui/react'

import CardPermissions from '@/components/Cards/Permissions'
import { TPermissions } from '@/components/Cards/Permissions/types'

const loremIpsum = `Mussum Ipsum, cacilds Vidis litro abertis. Näo sou faixa
                      preta cumpadi, sou preto inteiris, inteiris. A ordem dos
                      tratores näo altera o päo duris. Nec orci ornare
                      consequat. Praesent lacinia ultrices consectetur.`

const tabItemsPermissions = [
  {
    id: 'usuarios',
    label: 'Usuários',
    permissions: [
      {
        key: 'vincular-desvincular',
        title: 'Vincular e desvincular usuários',
        description: loremIpsum,
      },
      {
        key: 'perfil',
        title: 'Perfil de usuário',
        description: loremIpsum,
      },
    ],
  },
  {
    id: 'equipes',
    label: 'Equipes',
  },
  {
    id: 'permissions',
    label: 'Permissões',
  },
  {
    id: 'objetos',
    label: 'Objetos',
  },
  {
    id: 'formularios',
    label: 'Formulários',
  },
  {
    id: 'digiligencias',
    label: 'Diligências',
  },
]

const Permissions = () => {
  const handlePermissionsChange = (permissions: TPermissions) => {
    // TODO: Do something with the permissions
  }

  return (
    <Stack gap={4}>
      <CardPermissions
        tabItems={tabItemsPermissions}
        onChange={handlePermissionsChange}
      />
    </Stack>
  )
}

export default Permissions
