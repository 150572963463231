import React from 'react'

import { ITabItem } from '@/components/Tabs/types'

import Phase1Teams from './Teams'
import { exampleData as exampleTeams } from './Teams/exampleData'
import Phase1Volumes from './Volumes'
import { exampleData as exampleVolumes } from './Volumes/exampleData'

export const tabs: ITabItem[] = [
  {
    id: 'volumes',
    label: 'Volumes',
    render: () => <Phase1Volumes volumes={exampleVolumes()} />,
  },
  {
    id: 'equipes',
    label: 'Equipes',
    render: () => <Phase1Teams teams={exampleTeams()} />,
  },
]
