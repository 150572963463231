import React from 'react'
import { FaFile, FaQuestionCircle, FaUser } from 'react-icons/fa'
import { Outlet } from 'react-router-dom'

import { Formik } from 'formik'

import {
  Stack,
  Text,
  Flex,
  Tag,
  Center,
  Icon,
  Button,
  Box,
} from '@chakra-ui/react'

import { Input } from '@/components/Forms'

const TeamRedistribution = () => {
  return (
    <Stack gap={6} flex={1}>
      <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
        <Flex justify="space-between">
          <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
            Redistribuição de equipes
          </Text>
          <Tag
            bg="brand.primary.light_2"
            color="brand.primary.dark_1"
            fontWeight="medium"
          >
            Análise de Atributos
          </Tag>
        </Flex>
        <Formik
          initialValues={{
            name: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
            code: '0172 P22 02 02 000 000',
            notice: '2023-24',
          }}
          onSubmit={() => {}}
        >
          <Flex gap={4}>
            <Input isDisabled name="name" label="Objeto" flex={2} />
            <Input isDisabled name="code" label="Código do Objeto" flex={1} />
            <Input isDisabled name="notice" label="Edital" flex={1} />
          </Flex>
        </Formik>
      </Stack>
      <Flex p={5} boxShadow="md" borderRadius="base" flex={1}>
        <Outlet />
      </Flex>
    </Stack>
  )
}

export default TeamRedistribution
