import React from 'react'
import InputMask from 'react-input-mask'
import { useNavigate } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik'
import * as yup from 'yup'

import {
  Box,
  VStack,
  Image,
  Hide,
  Text,
  FormLabel,
  FormControl,
  InputGroup,
  Input,
  useToast,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import { useMutationPostAuthLocalLogin } from '@/api/auth/local/mutations'
import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/logo-sare-b-nd-m-BB.svg'
import S from '@/common/Templates/TemplateLogin/styles'
import { getErrorDetails } from '@/utils/error'

export interface IFormValues {
  cpf: string
  password: string
}

const LoginDev: React.FC = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { colorMode } = useColorMode()

  const { mutate: postAuthLocalLogin, isLoading: isLoadingAuthLocalLogin } =
    useMutationPostAuthLocalLogin()

  const handleSubmit = (values: IFormValues) => {
    const { cpf, password } = values
    const cpfWithoutMask = cpf.replace(/\D/g, '')

    postAuthLocalLogin(
      {
        ds_cpf: cpfWithoutMask,
        ds_password: password,
      },
      {
        onSuccess: data => {
          if (data.first_access) {
            navigate('/login/primeiro-acesso')
          }

          navigate('/')
        },
        onError: error => {
          const statusCode = error.response?.status

          if (statusCode === 401) {
            navigate('/login/acesso-negado')

            return
          }

          toast({
            status: 'error',
            title: 'Ops! Ocorreu um erro',
            description: getErrorDetails(error),
            isClosable: true,
          })
        },
      }
    )
  }

  return (
    <S.MainBox maxWidth="564px" bg={useColorModeValue('white', 'transparent')}>
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image
            height="75px"
            src={useColorModeValue(ColorfulLogoSARE, WhiteLogoSARE)}
            alt="Logo Sare"
          />
        </Box>
      </Hide>
      <S.Title color="brand.neutral.dark_2">
        Seja bem-vindo(a) ao
        <br />
        Sistema de Análise de Recursos Educacionais
      </S.Title>
      <Formik
        initialValues={{
          cpf: '',
          password: '',
        }}
        validationSchema={yup.object().shape({
          cpf: yup
            .string()
            .required('Este campo é obrigatório')
            .test('test-cpf', 'O CPF está incompleto', value => {
              return !value.includes('_')
            }),
          password: yup.string().required('Este campo é obrigatório'),
        })}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        <Form>
          <VStack width="full" gap="4">
            <Box width="full">
              <FormLabel fontSize="sm">CPF</FormLabel>
              <Field name="cpf">
                {({ field, form }: FieldProps) => {
                  return (
                    <FormControl>
                      <InputGroup>
                        <Input
                          as={InputMask}
                          mask="999.999.999-99"
                          variant="filled"
                          placeholder="Digite seu CPF"
                          value={field.value}
                          onChange={e =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      </InputGroup>
                      <ErrorMessage name="cpf">
                        {(message: string) => (
                          <Text color="red.600" fontSize="sm">
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )
                }}
              </Field>
            </Box>
            <Box width="full">
              <FormLabel fontSize="sm">Senha</FormLabel>
              <Field name="password">
                {({ field, form }: FieldProps) => {
                  return (
                    <FormControl>
                      <InputGroup>
                        <Input
                          type="password"
                          variant="filled"
                          placeholder="Digite sua senha"
                          value={field.value}
                          onChange={e =>
                            form.setFieldValue(field.name, e.target.value)
                          }
                        />
                      </InputGroup>
                      <ErrorMessage name="password">
                        {(message: string) => (
                          <Text color="red.600" fontSize="sm">
                            {message}
                          </Text>
                        )}
                      </ErrorMessage>
                    </FormControl>
                  )
                }}
              </Field>
            </Box>
          </VStack>
          <Box width="full" marginTop="8">
            <S.MainButton
              type="submit"
              variant={useColorModeValue('solid', 'outline')}
              border={useColorModeValue('none', '1px solid #FF0000')}
              color={useColorModeValue('white', '#FF0000')}
              bg={useColorModeValue('#C63637', 'transparent')}
              _hover={useColorModeValue(
                { bg: '#C63637EB', color: 'white' },
                { bg: '#FF000099', color: 'white' }
              )}
              width="full"
              isLoading={isLoadingAuthLocalLogin}
            >
              Entrar como desenvolvedor
            </S.MainButton>
          </Box>
        </Form>
      </Formik>
    </S.MainBox>
  )
}

export default LoginDev
