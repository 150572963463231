import React from 'react'
import { FaChevronLeft, FaCalculator } from 'react-icons/fa'

import { Formik } from 'formik'

import { Stack, Box, Text, Flex, Tag, useSteps, Button } from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import Stepper from '@/components/Stepper'

import EffertCalculator from './EffortCalculator'
import ParameterSetting from './ParameterSetting'

const steps = [
  { title: 'Passo 1', description: 'Definição de parâmetros' },
  { title: 'Passo 2', description: 'Calculadora de esforço' },
]

const VolumeSizing = () => {
  const { activeStep, goToPrevious, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  return (
    <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
      <Flex justify="space-between">
        <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
          Dimensionamento de Volumes
        </Text>
        <Tag
          bg="brand.primary.light_2"
          color="brand.primary.dark_1"
          fontWeight="medium"
        >
          Análise de Atributos
        </Tag>
      </Flex>
      <Formik
        initialValues={{
          name: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
          code: '0172 P22 02 02 000 000',
          notice: '2023-24',
        }}
        onSubmit={() => {}}
      >
        <Flex gap={4}>
          <Input isDisabled name="name" label="Nome do Volume" flex={2} />
          <Input isDisabled name="code" label="Código do Objeto" flex={1} />
          <Input isDisabled name="notice" label="Edital" flex={1} />
        </Flex>
      </Formik>
      <Stepper
        size="sm"
        steps={steps}
        index={activeStep}
        onLastStep={() => {
          setActiveStep(0)
        }}
      >
        <ParameterSetting goToNext={goToNext} goToPrevious={goToPrevious} />
        <EffertCalculator goToNext={goToNext} goToPrevious={goToPrevious} />
        <Box>
          <Flex justify="space-between">
            <Button
              size="sm"
              key="go-back"
              variant="ghost"
              leftIcon={<FaChevronLeft />}
              color="brand.primary.dark_1"
              onClick={goToPrevious}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              size="sm"
              bg="brand.primary.dark_1"
              _hover={{ bg: 'brand.primary.dark_2' }}
              color="white"
              leftIcon={<FaCalculator />}
              onClick={goToNext}
            >
              Próximo
            </Button>
          </Flex>
        </Box>
      </Stepper>
    </Stack>
  )
}

export default VolumeSizing
