import http from '@/api/http'

import {
  TCreaTeamResponse,
  TCreateTeam,
  TDeleteTeamParams,
  TDeleteTeamResponse,
  TGetMembersRolesResponse,
  TGetSupervisorResponse,
  TGetSupervisorsParams,
  TGetTeamResponse,
  TGetTeamsPaginatedParams,
  TGetTeamsPaginatedResponse,
  TGetTeamsParams,
  TGetTeamsResponse,
} from './types'

export const getTeams = async () => {
  const response = await http.get<TGetTeamsResponse>('/team/')

  return response.data
}

export const getTeamsPaginated = async ({
  page = 1,
  pageSize = 10,
}: TGetTeamsPaginatedParams) => {
  const response = await http.get<TGetTeamsPaginatedResponse>(
    `/team/?page=${page}&page_size=${pageSize}`
  )

  return response.data
}

export const getTeam = async ({ coTeam }: TGetTeamsParams) => {
  const response = await http.get<TGetTeamResponse>(`/team/${coTeam}/`)

  return response.data
}

export const getMemberRoles = async () => {
  const response = await http.get<TGetMembersRolesResponse>(
    '/users/user_roles/'
  )

  return response.data
}

export const getSupervisors = async ({ coUser }: TGetSupervisorsParams) => {
  const response = await http.get<TGetSupervisorResponse>(
    `/users/management/${coUser}/`
  )

  return response.data
}

export const postCreateTeam = async (team: TCreateTeam) => {
  const response = await http.post<TCreaTeamResponse>('/team/', team)

  return response.data
}

export const postDeleteTeam = async (
  params: TDeleteTeamParams
): Promise<TDeleteTeamResponse> => {
  const response = await http.post<TDeleteTeamResponse>('/team/', params)
}
