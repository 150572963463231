import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusQuestionsProps, IStatusQuestionsTagStyles } from './types'

const styles: IStatusQuestionsTagStyles = {
  active: {
    name: 'Ativo',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  inactive: {
    name: 'Inativo',
    backgroundColor: '#FFF3F2',
    color: '#E60000',
  },
  pending: {
    name: 'Pendente',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
}

function StatusTag({ tag, ...rest }: IStatusQuestionsProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.pending

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
