import { AxiosError } from 'axios'

import { IErrorDTO } from '@/DTOs/errorDTO'

const defaultErrorMsg = 'Por favor, tente novamente mais tarde.'

/**
 * Extracts the first error detail from API response.
 *
 * @param {AxiosError<IErrorDTO>} error - The error object returned by Axios.
 * @returns {string} - The detail of the first error or a default error message.
 */
const getFirstErrorDetail = (error: AxiosError<IErrorDTO>): string => {
  try {
    const errorDetail = error.response?.data.errors[0]?.detail
    return errorDetail ?? defaultErrorMsg
  } catch {
    return defaultErrorMsg
  }
}

/**
 * Retrieves a user-friendly error message.
 *
 * @param {AxiosError<IErrorDTO>} error - The error object returned by Axios.
 * @returns {string} - A user-friendly error message.
 */
export function getErrorDetails(error: AxiosError<IErrorDTO>): string {
  if (error.response) {
    return getFirstErrorDetail(error)
  }

  if (error.request) {
    return defaultErrorMsg
  }

  return defaultErrorMsg
}
