import React from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { FaAngleLeft } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as yup from 'yup'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Textarea,
  Text,
  Flex,
  useToast,
} from '@chakra-ui/react'

import { useMutationCreatePermissionsGroup } from '@/api/user/permissions/mutations'
import Modal from '@/components/Modal'
import Select from '@/components/Select'

import { IPermissionGroupData, PermissionGroupProps } from '../types'

const options = [
  { value: '1', label: 'Nível 1' },
  { value: '2', label: 'Nível 2' },
  { value: '3', label: 'Nível 3' },
]

const PermissionModalGroup: React.FC<PermissionGroupProps> = ({
  isOpen = false,
  onClose,
  initialData,
  mode,
}) => {
  const navigate = useNavigate()
  const toast = useToast()
  const { mutate: createPermissionsGroup, isLoading } =
    useMutationCreatePermissionsGroup({
      onSuccess: () => {
        toast({
          title: 'Grupo de permissões criado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        navigate(`detalhes/${id}`)
        onClose()
      },
      onError: () => {
        toast({
          title: 'Erro ao criar grupo de permissões',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const isDuplicateMode = mode === 'duplicate'
  const modalTitle = isDuplicateMode
    ? 'Duplicar Grupo de Permissão'
    : 'Criar novo grupo de permissões'

  const initialValues: IPermissionGroupData = {
    groupName: initialData?.groupName || '',
    groupType: initialData?.groupType || '',
    description: initialData?.description || '',
  }

  const validationSchema = yup.object({
    groupName: yup.string().required('Campo obrigatório'),
    groupType: yup.object(),
    description: yup.string().required('Descrição é obrigatória'),
  })

  const id = 1
  const handleSubmit = (values: IPermissionGroupData) => {
    const payload = {
      name: values?.groupName,
      description: values?.description,
      dsStatus: true,
      permissions: [],
    }

    createPermissionsGroup(payload)
  }

  return (
    <Modal
      title={modalTitle}
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      closeOnOverlayClick={!isLoading}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Stack spacing={6}>
              <Flex>
                <FormControl pr={3} flex={1}>
                  <FormLabel fontSize="sm">Nome do grupo</FormLabel>
                  <Field
                    as={Input}
                    type="text"
                    name="groupName"
                    placeholder="Placeholder"
                    variant="filled"
                  />
                  <ErrorMessage name="groupName">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
                {/* <FormControl pl={3} flex={1}>
                  <FormLabel fontSize="sm">Nível de acesso</FormLabel>
                  <Field name="groupType">
                    {({ field, form }: FieldProps) => (
                      <Select
                        id={field.name}
                        name={field.name}
                        options={options}
                        variant="filled"
                        value={field.value}
                        onChange={option =>
                          form.setFieldValue(field.name, option)
                        }
                        isDisabled={isDuplicateMode}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="groupType">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl> */}
              </Flex>
              <FormControl>
                <FormLabel fontSize="sm">Descrição</FormLabel>
                <Field
                  as={Textarea}
                  type="text"
                  name="description"
                  placeholder="Placeholder"
                  variant="filled"
                />
                <ErrorMessage name="description">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
                <Text color="#636363" fontSize="sm">
                  Descreva aqui as principais funções desse grupo de permissões
                </Text>
              </FormControl>
            </Stack>
            <Flex justifyContent="flex-end" mt={4}>
              <Button
                leftIcon={<FaAngleLeft />}
                bg="#FFFFFF"
                color="brand.neutral.base"
                size="sm"
                onClick={onClose}
                isDisabled={isLoading}
              >
                Voltar
              </Button>
              <Button
                ml={2}
                leftIcon={<BsCheckCircle />}
                bg="brand.primary.dark_1"
                color="#FFFFFF"
                size="sm"
                fontWeight="semibold"
                _hover={{
                  bg: 'brand.primary.dark_2',
                }}
                type="submit"
                isLoading={isLoading}
              >
                {isDuplicateMode ? 'Sim, duplicar' : 'Sim, adicionar'}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default PermissionModalGroup
