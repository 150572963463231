import React from 'react'

import { SearchIcon } from '@chakra-ui/icons'

import { IFilter } from '@/components/DataFilter/types'
import { IHeader } from '@/components/DataTable/types'
import Tag from '@/components/Tag'

import { ITeamProps } from './types'

export const filterExample: Array<IFilter> = [
  {
    name: 'filter1',
    label: 'Filter 1',
    type: 'text',
    icon: <SearchIcon />,
  },
  {
    name: 'filter2',
    label: 'Filter 2',
    type: 'text',
    icon: <SearchIcon />,
  },
  {
    name: 'filter3',
    label: 'Filter 3',
    type: 'select',
    options: [
      { label: 'Option 1', value: 'option1' },
      { label: 'Option 2', value: 'option2' },
    ],
  },
]

export const analystsFilterExample: Array<IFilter> = [
  {
    name: 'filter3',
    label: 'Status',
    type: 'select',
    options: [
      { label: 'Ativo', value: 'ativo' },
      { label: 'Inativo', value: 'Inativo' },
    ],
  },
]
export const tableHeaders: Array<IHeader> = [
  {
    name: 'EQUIPE',
    key: 'user',
    type: 'element',
    width: '40%',
  },
  {
    name: 'SUPERVISOR',
    key: 'supervisor',
    type: 'element',
    align: 'center',
    width: '20%',
  },
  {
    name: 'DATA DE CRIAÇÃO',
    key: 'date',
    align: 'center',
    width: '15%',
  },
  {
    name: 'STATUS',
    key: 'status',
    type: 'element',
    align: 'center',
    width: '20%',
  },
  {
    name: '',
    key: 'edit',
    type: 'element',
    width: '5%',
  },
]

export const initialCards: ITeamProps[] = [
  {
    id: '1',
    title: 'Equipe',
    description: 'EQVI2450000',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.000-00',
    },
    status: {
      tag: 'active',
    },
  },
  {
    id: '2',
    title: 'Equipe',
    description: 'EQAF0000000',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.000-00',
    },
    status: {
      tag: 'inactive',
    },
  },
  {
    id: '3',
    title: 'Equipe',
    description: 'EQIQ0000000',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.0000-00',
    },
    status: {
      tag: 'finished',
    },
  },
  {
    id: '4',
    title: 'Equipe',
    description: 'EQAC0000000',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.0000-00',
    },
    status: {
      tag: 'finished',
    },
  },
  {
    id: '5',
    title: 'Equipe',
    description: 'EQAD00034500',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.0000-00',
    },
    status: {
      tag: 'waiting_objects',
    },
  },
  {
    id: '6',
    title: 'Equipe',
    description: 'EQAE18034500',
    date: '00/00/0000',
    supervisor: {
      label: 'Fulano de tal',
      value: '00.000.0000-00',
    },
    status: {
      tag: 'waiting_members',
    },
  },
]

// Aguardando objetos
export const tableDataExample1 = [
  {
    name: 'Dalgoberto Miquili',
    permission: 'Supervisor',
    objects: '-',
    phase: 'Validação de Inscrição',
    status: <Tag group="statusCardTeam" value="waiting_objects" />,
  },
  {
    name: 'Nome e Sobrenome',
    permission: 'Analista 1',
    objects: '-',
    phase: 'Validação de Inscrição',
    status: <Tag group="statusCardTeam" value="waiting_objects" />,
  },
  {
    name: 'Nome e Sobrenome',
    permission: 'Analista 1',
    objects: '-',
    phase: 'Validação de Inscrição',
    status: <Tag group="statusCardTeam" value="waiting_objects" />,
  },
  {
    name: 'Nome e Sobrenome',
    permission: 'Analista 1',
    objects: '-',
    phase: 'Validação de Inscrição',
    status: <Tag group="statusCardTeam" value="waiting_objects" />,
  },
]
