import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import {
  getAddressCountries,
  getAddressStates,
  getAddressCities,
} from './services'
import {
  TGetAddressCountriesResponse,
  TGetAddressStatesParams,
  TGetAddressStatesResponse,
  TGetAddressCitiesParams,
  TGetAddressCitiesResponse,
} from './types'

export const useQueryGetCountries = (
  options?: UseQueryOptions<TGetAddressCountriesResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getAddressCountries()

  return useQuery<TGetAddressCountriesResponse, AxiosError<IErrorDTO>>(
    useQueryGetCountries.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryGetCountries.getFullQueryKey = (): QueryKey => ['address', 'countries']

useQueryGetCountries.queryKey = ['address', 'countries']

export const useQueryGetStates = (
  params: TGetAddressStatesParams,
  options?: UseQueryOptions<TGetAddressStatesResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getAddressStates(params)

  return useQuery<TGetAddressStatesResponse, AxiosError<IErrorDTO>>(
    useQueryGetStates.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryGetStates.getFullQueryKey = (
  params: TGetAddressStatesParams
): QueryKey => ['address', 'states', params]

useQueryGetStates.queryKey = ['address', 'states']

export const useQueryGetCities = (
  params: TGetAddressCitiesParams,
  options?: UseQueryOptions<TGetAddressCitiesResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getAddressCities(params)

  return useQuery<TGetAddressCitiesResponse, AxiosError<IErrorDTO>>(
    useQueryGetCities.getFullQueryKey(params),
    queryFunction,
    options
  )
}

useQueryGetCities.getFullQueryKey = (
  params: TGetAddressCitiesParams
): QueryKey => ['address', 'cities', params]

useQueryGetCities.queryKey = ['address', 'cities']
