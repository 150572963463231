import React, { useEffect, useState } from 'react'

import { Box, Select, useColorMode, useToast } from '@chakra-ui/react'

import {
  useQueryGetCountries,
  useQueryGetStates,
  useQueryGetCities,
} from '@/api/address/queries'
import { useQueryGetSpecialNeeds } from '@/api/user/auxiliaries/queries'
import { useQueryUser } from '@/api/user/management/queries'
import { IGetAccountDTO } from '@/DTOs/user/account.dto'

import { IUserDetailsProps } from '../types'
import S from './styles'

const UserInfoForm: React.FC<IUserDetailsProps> = ({ userId }) => {
  const toast = useToast()

  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [userData, setUserData] = useState<IGetAccountDTO>()

  const {
    data: specialNeeds,
    isLoading: isLoadingSpecialNeeds,
    isError: isErrorSpecialNeeds,
  } = useQueryGetSpecialNeeds()

  const { data: countries = [], isLoading: isLoadingCountries } =
    useQueryGetCountries({
      onError: () => {
        toast({
          title: 'Erro ao buscar países',
          description: 'Ocorreu um erro ao buscar os países.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    })
  const { data: states = [], isLoading: isLoadingStates } = useQueryGetStates(
    {
      country: selectedCountry,
    },
    {
      onError: () => {
        toast({
          title: 'Erro ao buscar estados',
          description: 'Ocorreu um erro ao buscar os estados.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )
  const { data: cities = [], isLoading: isLoadingCities } = useQueryGetCities(
    {
      uf: selectedState,
      country: selectedCountry,
    },
    {
      onError: () => {
        toast({
          title: 'Erro ao buscar cidades',
          description: 'Ocorreu um erro ao buscar as cidades.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const { data, isLoading, isError } = useQueryUser(
    { coUser: userId },
    {
      enabled: !!userId,
      onError: () => {
        toast({
          title: 'Erro ao buscar usuário',
          description: 'Ocorreu um erro ao buscar o usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )
  useEffect(() => {
    if (data && !isLoading && !isError) {
      setUserData(data)
    }
  }, [userId, data, isLoading, isError])

  const isAdmin = userData?.ncoProfile[0] === 1
  const isStateSelectDisabled = !selectedCountry || isAdmin
  const isCitySelectDisabled = !selectedState || isAdmin

  return (
    <Box width="100%">
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Nome completo</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            placeholder="Nome"
            value={userData?.noUserName || ''}
            required
          />
        </S.StyledColumn>

        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Nome social</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            placeholder="Digite seu nome social"
            value={userData?.noUserSocialName || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">CPF</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            placeholder="00.000.000-00"
            value={userData?.dsCpf || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
            required
          />
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Data de nascimento</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="date"
            value={userData?.dtBirthDate || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Deficiência</S.LabelText>
          </S.StyledFormLabel>
          <Select
            variant="filled"
            value={userData?.coSpecialNeed || ''}
            isDisabled={isAdmin || isLoadingSpecialNeeds}
            width="100%"
          >
            <option value="">Selecione uma opção</option>
            {!isLoadingSpecialNeeds &&
              !isErrorSpecialNeeds &&
              specialNeeds?.map(need => (
                <option key={need.coSpecialNeed} value={need.coSpecialNeed}>
                  {need.dsSpecialNeed}
                </option>
              ))}
          </Select>
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Pronome</S.LabelText>
          </S.StyledFormLabel>
          <Select
            variant="filled"
            value={userData?.dsPronoumn || ''}
            isDisabled={isAdmin}
          >
            <option value="">Selecione uma opção</option>
            <option value="nenhum">Nenhum</option>
            <option value="ele_dele">Ele/Dele</option>
            <option value="ela_dela">Ela/Dela</option>
            <option value="elu_delu">Elu/Delu</option>
            <option value="ile_dile">Ile/Dile</option>
          </Select>
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Nível educacional</S.LabelText>
          </S.StyledFormLabel>
          <Select variant="filled" value={userData?.coEducationLevel || ''}>
            <option value="">Selecione uma opção</option>
            <option value="fundamental_completo">Fundamental Completo</option>
            <option value="medio_incompleto">Médio Incompleto</option>
            <option value="medio_completo">Médio Completo</option>
            <option value="superior_incompleto">Superior Incompleto</option>
            <option value="superior_completo">Superior Completo</option>
            <option value="pos_graduacao">Pós-graduação</option>
          </Select>
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.HeaderText color="brand.primary.dark_1">Contatos</S.HeaderText>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Email institucional</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="email"
            placeholder="nome@nees.ufal.br"
            value={userData?.dsInstitutionalEmail || ''}
            required
          />
          <S.InfoText color="brand.neutral.dark_1">
            Escreva aqui o e-mail que deseja receber as notificações do sare.
          </S.InfoText>
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Email do gov.br</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="email"
            placeholder="nome@email.com.br"
            value={userData?.dsGovbrEmail || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
          {isAdmin ? (
            <S.InfoText color="brand.neutral.dark_1">
              Esse campo é preenchido automaticamente pelo govbr e não pode ser
              alterado.
            </S.InfoText>
          ) : (
            <></>
          )}
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Telefone</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="tel"
            placeholder="+55 82 99999-9999"
            value={userData?.dsCellphone || ''}
          />
        </S.StyledColumn>
      </S.Row>
      <S.Row>
        <S.HeaderText color="brand.primary.dark_1">Endereço</S.HeaderText>
      </S.Row>
      <S.Row>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">País</S.LabelText>
          </S.StyledFormLabel>
          <Select
            placeholder="Selecione um país"
            onChange={e => setSelectedCountry(e.target.value)}
            isDisabled={isAdmin}
          >
            {countries.map(country => (
              <option
                key={`country-${country.geonameId}`}
                value={country.geonameId}
              >
                {country.countryName}
              </option>
            ))}
          </Select>
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Estado/província</S.LabelText>
          </S.StyledFormLabel>
          <Select
            placeholder="Selecione um estado"
            onChange={e => setSelectedState(e.target.value)}
            isDisabled={isStateSelectDisabled}
          >
            {states.map(state => (
              <option key={`state-${state.geonameId}`} value={state.geonameId}>
                {state.name}
              </option>
            ))}
          </Select>
        </S.StyledColumn>
        <S.StyledColumn>
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Cidade</S.LabelText>
          </S.StyledFormLabel>
          <Select
            placeholder="Selecione uma cidade"
            onChange={e => setSelectedCity(e.target.value)}
            isDisabled={isCitySelectDisabled}
          >
            {cities.map(city => (
              <option key={`city-${city.geonameId}`} value={city.geonameId}>
                {city.name}
              </option>
            ))}
          </Select>
        </S.StyledColumn>
      </S.Row>
    </Box>
  )
}

export default UserInfoForm
