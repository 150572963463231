import React, { useRef } from 'react'
import { BsCheckCircle, BsChevronLeft } from 'react-icons/bs'
import { FaCircleExclamation } from 'react-icons/fa6'
import { useNavigate } from 'react-router-dom'

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
} from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  SimpleGrid,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'

import Select from '@/components/Select'
import Tabs from '@/components/Tabs'

import { failsListTabs } from './Data'
import { TRegisterFailsGroupFormValues } from './types'

const RegisterFailsGroupChecklist: React.FC = () => {
  const navigate = useNavigate()

  const formikRef = useRef<FormikProps<TRegisterFailsGroupFormValues>>(null)

  const validationSchema = yup.object({
    notice: yup.string().required('Edital é obrigatório'),
    object: yup.string().required('Objeto é obrigatório'),
    phases: yup.string().required('Fase é obrigatória'),
  })

  const onSubmit = (values: TRegisterFailsGroupFormValues) => {
    const payload = {
      notice: values.notice,
      object: values.object,
      phases: values.phases,
    }
  }

  return (
    <Stack gap={6}>
      <Flex
        padding={4}
        borderRadius="base"
        backgroundColor="brand.highlight.light_2"
      >
        <Icon
          as={FaCircleExclamation}
          boxSize="24px"
          color="brand.highlight.dark_2"
          marginRight={2}
        />

        <Text fontWeight="bold" fontSize="md" color="brand.neutral.dark_2">
          Preencha todos os códigos de falha para salvar o grupo!
        </Text>
      </Flex>
      <Skeleton isLoaded borderRadius="base">
        <Box p={5} boxShadow="md" borderRadius="base">
          <Formik
            innerRef={formikRef}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            initialValues={{
              notice: '',
              object: '',
              phases: '',
            }}
          >
            <Form>
              <Stack gap={4}>
                <SimpleGrid columns={2} rowGap={4} columnGap={6}>
                  <FormControl>
                    <FormLabel fontSize="sm">Edital</FormLabel>
                    <Field name="notice">
                      {({ field, form }: FieldProps) => (
                        <Select
                          value={field.value}
                          variant="filled"
                          placeholder="Selecione o edital"
                          isSearchable={false}
                          options={[
                            { value: '2022', label: '2021/22' },
                            { value: '2023', label: '2022/23' },
                            { value: '2024', label: '2023/24' },
                          ]}
                          onChange={option => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="notice">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="sm">Objeto</FormLabel>
                    <Field name="object">
                      {({ field, form }: FieldProps) => (
                        <Select
                          value={field.value}
                          variant="filled"
                          placeholder="Selecione o objeto"
                          isSearchable={false}
                          options={[
                            { value: 'id001', label: 'ID001' },
                            { value: 'id002', label: 'ID002' },
                            { value: 'id003', label: 'ID003' },
                          ]}
                          onChange={option => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="object">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <FormControl>
                  <FormLabel fontSize="sm">Fases</FormLabel>
                  <Field name="phases">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="Selecione as fases"
                        isSearchable={false}
                        options={[
                          { value: '01', label: 'Fase 01' },
                          { value: '02', label: 'Fase 02' },
                          { value: '03', label: 'Fase 03' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="phases">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Stack>
            </Form>
          </Formik>
        </Box>
      </Skeleton>
      <Skeleton isLoaded borderRadius="base">
        <Box p={5} boxShadow="md" borderRadius="base">
          <Stack gap={4}>
            <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
              Lista de falhas
            </Text>
            <Tabs variant="rounded" items={failsListTabs} initialTab="pdf" />
            <Flex justifyContent="space-between" gap="2">
              <Button
                size="sm"
                leftIcon={<BsChevronLeft />}
                variant="ghost"
                fontSize="sm"
                color="brand.primary.dark_1"
                onClick={() => navigate('/formularios/falhas')}
              >
                Voltar
              </Button>
              <Button
                size="sm"
                leftIcon={<BsCheckCircle />}
                fontSize="sm"
                color="white"
                bg="brand.primary.dark_1"
                _hover={{
                  bg: 'brand.primary.dark_2',
                }}
                // isLoading={}
                // isDisabled={}
                // onClick={}
              >
                Salvar grupo
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Skeleton>
    </Stack>
  )
}

export default RegisterFailsGroupChecklist
