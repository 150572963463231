import React, { useRef } from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik'
import * as yup from 'yup'

import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'

import Modal from '@/components/Modal'
import Select from '@/components/Select'

import S from './styles'
import { IRegisterFailModalProps, TRegisterFailFormValues } from './types'

const RegisterFailModal: React.FC<IRegisterFailModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const formikRef = useRef<FormikProps<TRegisterFailFormValues>>(null)

  const validationSchema = yup.object({
    format: yup.string().required('O formato é obrigatório'),
    code: yup.string().required('O código é obrigatório'),
    failTitle: yup.string().required('O título é obrigatório'),
    description: yup.string().required('A descrição é obrigatória'),
  })

  const onSubmit = (values: TRegisterFailFormValues) => {
    const payload = {
      format: values.format,
    }
  }

  return (
    <Modal
      title="Adicionar falha não cadastrada"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          format: '',
          code: '',
          failTitle: '',
          description: '',
        }}
      >
        <Form>
          <Stack gap={4}>
            <FormControl>
              <FormLabel fontSize="sm">Formato do volume</FormLabel>
              <Field name="format">
                {({ field, form }: FieldProps) => (
                  <Select
                    value={field.value}
                    variant="filled"
                    placeholder="Formato do volume"
                    isSearchable={false}
                    options={[
                      { value: 'formato1', label: 'Formato 1' },
                      { value: 'formato2', label: 'Formato 2' },
                      { value: 'formato3', label: 'Formato 3' },
                    ]}
                    onChange={option => {
                      form.setFieldValue(field.name, option)
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="format">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm">Código da falha</FormLabel>
              <Field
                as={Input}
                type="name"
                name="code"
                placeholder="---"
                variant="filled"
              />
              <ErrorMessage name="code">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel fontSize="sm">Título da falha</FormLabel>
              <Field
                as={Input}
                type="name"
                name="failTitle"
                placeholder="---"
                variant="filled"
              />
              <ErrorMessage name="failTitle">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
            <FormControl as={Stack}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Field
                as={Textarea}
                type="text"
                name="description"
                placeholder="Descrição"
                variant="filled"
                maxH="142px"
                resize="none"
              />
              <Text fontSize="sm" color="brand.neutral.dark_1">
                Descreva aqui as principais características dessa falha
              </Text>
              <ErrorMessage name="description">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
          </Stack>
        </Form>
      </Formik>
      <S.ModalActionButtons>
        <Button
          key="go-back"
          variant="outline"
          size="sm"
          onClick={onClose}
          borderRadius={50}
        >
          Cancelar
        </Button>
        <Button
          key="confirm"
          size="sm"
          bg="brand.primary.dark_1"
          color="white"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          onClick={onConfirm}
          borderRadius={50}
        >
          Cadastrar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default RegisterFailModal
