import React, { useEffect, useMemo, useState } from 'react'
import { BsCheckCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import { Button, Text, Stack } from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

import S from '../styles'
import { IBlockedUsersModalProps } from './types'
const BlockedUsersModal: React.FC<IBlockedUsersModalProps> = ({
  isOpen,
  onClose,
  users,
  onConfirm,
}) => {
  const [page, setPage] = useState(0)
  const usersTableHeaders: Array<IHeader> = [
    {
      name: 'NOME',
      key: 'name',
    },
    {
      name: 'CPF',
      key: 'cpf',
    },
    {
      name: 'EMAIL',
      key: 'email',
    },
  ]

  const pageInfo = useMemo(() => {
    const itemsPerPage = 3

    return {
      itemsPerPage,
      pageCount: Math.ceil(users.length / itemsPerPage),
      itemsCount: users.length,
    }
  }, [users])

  const usersPaginated = useMemo(() => {
    const start = page * pageInfo.itemsPerPage
    const end = start + pageInfo.itemsPerPage

    return users.slice(start, end)
  }, [page, users])

  return (
    <Modal
      title="Atenção!"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Text fontSize="xs">
        Alguns dos usuários selecionados estão ativos em um objeto e não podem
        ser desvinculados. Confira a lista de usuários não desvinculados:
      </Text>
      <Stack gap="4">
        <Stack gap="4">
          <DataTable
            rowId="co_user"
            headers={usersTableHeaders}
            data={usersPaginated}
          />
          <Text
            fontSize="xs"
            fontWeight="bold"
            color="gray.600"
            textTransform="uppercase"
            textAlign="end"
          >
            Total de usuários: {users.length}
          </Text>
        </Stack>
        <Pagination
          itemsPerPage={pageInfo.itemsPerPage}
          pageCount={pageInfo.pageCount}
          itemsCount={pageInfo.itemsCount}
          onChangePageInfo={({ pageIndex }) => setPage(pageIndex)}
        />
      </Stack>
      <S.ModalActionButtons>
        <Button
          key="go-back"
          variant="ghost"
          size="sm"
          leftIcon={<FaChevronLeft />}
          color="brand.primary.dark_1"
          onClick={onClose}
        >
          Voltar
        </Button>
        <Button
          key="confirm"
          size="sm"
          leftIcon={<BsCheckCircle />}
          bg="brand.primary.dark_1"
          color="white"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          onClick={onConfirm}
        >
          Ok, estou ciente.
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default BlockedUsersModal
