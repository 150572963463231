import { styled, Flex, Divider as ChakraDivider } from '@chakra-ui/react'

const Pagination = styled(Flex, {
  baseStyle: {
    display: 'flex',
    flexDirection: { base: 'column', lg: 'row' },
    justifyContent: 'space-between',
    py: 2,
    rowGap: 2,
  },
})

const ItemsCounter = styled(Flex, {
  baseStyle: {
    alignItems: 'center',
    display: {
      md: 'flex',
      base: 'none',
    },
    gap: 4,
    mr: 4,
  },
})

const PageCounter = styled(Flex, {
  baseStyle: {
    display: {
      md: 'none',
      base: 'flex',
    },
  },
})

const Divider = styled(ChakraDivider, {
  baseStyle: {
    color: 'brand.neutral.light_1',
    border: '1px',
    h: '2rem',
  },
})

export default {
  Pagination,
  ItemsCounter,
  PageCounter,
  Divider,
}
