import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import {
  postUserPreRegistration,
  postRenewalUser,
  postUnlinkUser,
  patchMyAccount,
} from './services'
import {
  TCreateUserInBatchParams,
  TCreateUserInBatchResponse,
  TRenewalUsersParams,
  TRenewalUserResponse,
  TUnlinkUserParams,
  TUnlinkUserResponse,
  TUpdateMyAccountParams,
  TGetUserResponse,
} from './types'

export const useMutationPreRegistration = (
  options?: UseMutationOptions<
    TCreateUserInBatchResponse,
    IErrorDTO,
    TCreateUserInBatchParams
  >
) => {
  return useMutation(postUserPreRegistration, options)
}

export const useMutationRenewalUsers = (
  options?: UseMutationOptions<
    TRenewalUserResponse,
    AxiosError<IErrorDTO>,
    TRenewalUsersParams
  >
) => {
  return useMutation(postRenewalUser, options)
}

export const useMutationUnlinkUsers = (
  options?: UseMutationOptions<
    TUnlinkUserResponse,
    AxiosError<IErrorDTO>,
    TUnlinkUserParams
  >
) => {
  return useMutation(postUnlinkUser, options)
}

export const useMutationUpdateMyAccount = (
  options?: UseMutationOptions<
    TGetUserResponse,
    IErrorDTO,
    TUpdateMyAccountParams
  >
) => {
  return useMutation(patchMyAccount, options)
}
