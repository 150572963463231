import React from 'react'
import { Link } from 'react-router-dom'

import {
  Box,
  Flex,
  Image,
  Hide,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'

import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/logo-sare-b-nd-m-BB.svg'
import S from '@/common/Templates/TemplateLogin/styles'

const LoginAccessDenied: React.FC = () => {
  return (
    <S.MainBox maxWidth="596px" bg={useColorModeValue('white', 'transparent')}>
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image
            height="75px"
            src={useColorModeValue(ColorfulLogoSARE, WhiteLogoSARE)}
            alt="Logo Sare"
          />
        </Box>
      </Hide>
      <Stack gap={4}>
        <S.Title color={useColorModeValue('brand.error.base', 'white')}>
          Acesso Negado
        </S.Title>
        <S.Subtitle color={useColorModeValue('brand.neutral.dark_2', 'white')}>
          Lamentamos, mas no momento seu acesso foi negado. Nossa equipe revisou
          suas credenciais e, infelizmente, sua solicitação não foi autorizada
          neste momento.
          <br />
          <br />
          Para esclarecimentos ou assistência, entre em contato com nosso
          suporte
        </S.Subtitle>
        <Flex align="center" justify="center">
          <Link to="https://atendimento.nees.ufal.br/" target="_blank">
            <S.MainButton
              variant={useColorModeValue('solid', 'dark')}
              border={useColorModeValue('none', '1px solid #FFFF00')}
              color={useColorModeValue('white', '#FFFF00')}
              bg={useColorModeValue('brand.primary.dark_1', 'transparent')}
              _hover={useColorModeValue(
                { bg: 'brand.primary.dark_2', color: 'white' },
                { bg: '#FFFF0099', color: 'white' }
              )}
            >
              Fale conosco
            </S.MainButton>
          </Link>
        </Flex>
      </Stack>
    </S.MainBox>
  )
}

export default LoginAccessDenied
