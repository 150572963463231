import { IHeader } from '@/components/DataTable/types'
import { IDeedDTO } from '@/DTOs/deeds/deedDTO'

import { IObjectFileData } from '../ManualImport/types'
import { IRowData } from './types'

export const processHeaders = (
  formatCounts: Record<FormatType, number>
): Array<IHeader> => {
  return [
    {
      name: 'Editora',
      key: '',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: ' ',
          key: 'publisher',
          type: 'element',
          width: 'auto',
          footer: 'Total Geral',
        },
      ],
    },
    {
      name: 'Código de Coleção',
      key: 'collectionCode',
      type: 'element',
      width: 'auto',
    },
    {
      name: 'Exemplar',
      key: 'sample',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: 'Formato',
          key: 'format',
          type: 'element',
          width: 'auto',
        },
      ],
    },
    {
      name: 'Livro do Estudante',
      key: 'student_book',
      type: 'element',
      width: 'auto',
      subHeader: [
        {
          name: 'Impresso',
          key: 'printed',
          type: 'element',
          width: 'auto',
          footer: formatCounts.printed.toString(),
        },
        {
          name: 'PDF',
          key: 'pdf',
          type: 'element',
          width: 'auto',
          footer: formatCounts.pdf.toString(),
        },
        {
          name: 'Vídeo',
          key: 'video',
          type: 'element',
          width: 'auto',
          footer: formatCounts.video.toString(),
        },
        {
          name: 'Áudio',
          key: 'audio',
          type: 'element',
          width: 'auto',
          footer: formatCounts.audio.toString(),
        },
        {
          name: 'HTML',
          key: 'html',
          type: 'element',
          width: 'auto',
          footer: formatCounts.html.toString(),
        },
        {
          name: 'Vídeo Tutorial',
          key: 'videoTutorial',
          type: 'element',
          width: 'auto',
          footer: formatCounts.videoTutorial.toString(),
        },
      ],
    },
  ]
}

type FormatType =
  | 'printed'
  | 'pdf'
  | 'video'
  | 'audio'
  | 'videoTutorial'
  | 'html'

export const mapCsvToTable = (data: IObjectFileData[]): IRowData[] => {
  if (!data) return []

  type FormatKeysType = { [key: string]: FormatType }

  const formatKeys: FormatKeysType = {
    impresso: 'printed',
    pdf: 'pdf',
    video: 'video',
    audio: 'audio',
    video_tutorial: 'videoTutorial',
    html: 'html',
  }

  const uniqueData: Record<string, IRowData> = {}

  data.forEach(item => {
    const key = `${item.editora}-${item.codigo_colecao}`
    if (!uniqueData[key]) {
      uniqueData[key] = {
        publisher: item.editora,
        collectionCode: item.codigo_colecao,
        format: null,
        printed: 0,
        pdf: 0,
        video: 0,
        audio: 0,
        videoTutorial: 0,
        html: 0,
        modalData: {
          notice: item.edital,
          object: item.objeto,
          publisher: item.editora,
          collectionTitle: item.codigo_colecao,
          collectionCode: item.titulo_colecao,
          details: [],
        },
      }
    }

    uniqueData[key].modalData.details.push({
      volumeCode: item.codigo_volume,
      version: item.versao,
      sample: item.exemplar,
      format: item.formato,
    })

    const formatKey = formatKeys[item.formato.toLowerCase()]
    if (formatKey) {
      uniqueData[key][formatKey]++
    }
  })

  return Object.values(uniqueData)
}

export const countFormats = (rowData: IRowData[]): Record<string, number> => {
  const countFormat: Record<FormatType, number> = {
    printed: 0,
    pdf: 0,
    video: 0,
    audio: 0,
    videoTutorial: 0,
    html: 0,
  }

  rowData.forEach(item => {
    countFormat.printed += item.printed
    countFormat.pdf += item.pdf
    countFormat.video += item.video
    countFormat.audio += item.audio
    countFormat.videoTutorial += item.videoTutorial
    countFormat.html += item.html
  })

  return countFormat
}

export const renameKeys = (data: IObjectFileData[]): IDeedDTO[] => {
  const newDeedDTO: IDeedDTO[] = []

  data.forEach(item => {
    const newDeed: IDeedDTO = {
      co_category: parseInt(item.id_categoria),
      co_cnpj: item.cnpj,
      co_collection_digital_pnld: item.id_inscricao_colecao,
      co_component: parseInt(item.id_componente),
      co_exemplary: parseInt(item.fixed_field_copy_id),
      co_format: parseInt(item.fixed_field_format_id),
      co_knowledge_area: parseInt(item.id_area),
      co_notice_digital_pnld: item.id_edital,
      co_object: parseInt(item.object_id),
      co_resource: parseInt(item.fixed_field_resource_id),
      co_series: parseInt(item.fixed_field_grade_id),
      co_volume_digital_pnld: item.id_inscricao_volume,
      co_volume_file_digital_pnld: item.document_id,
      ds_authors: item.autoria,
      ds_brand_description: item.descricao_marca,
      ds_category: item.categoria,
      ds_collection_code: item.codigo_colecao,
      ds_collection_title: item.titulo_colecao,
      ds_component: item.componente,
      ds_duration: item.duracao == '[NULL]' ? 'null' : item.duracao,
      ds_email: item.responsavel_email,
      ds_exemplary: item.exemplar,
      ds_format: item.formato,
      ds_knowledge_area: item.area,
      ds_metadata:
        item.palavras_chave == '[NULL]' ? 'null' : item.palavras_chave,
      ds_object: item.objeto,
      ds_originality_declaration: item.declaracao_ineditismo,
      ds_pedagogical_evaluation: item.avaliacao_pedagogica,
      ds_physical_attributes: item.atributos_fisicos,
      ds_publisher_responsible: item.responsavel_nome,
      ds_registry_validation: item.validacao_inscricao,
      ds_resource: item.recurso,
      ds_series: item.serie,
      ds_social_reason: item.editora,
      ds_street_address: item.endereco,
      ds_title: item.edital,
      ds_version: item.versao,
      ds_volume_code: item.codigo_volume,
      ds_volume_title: item.titulo_volume,
      dt_publication: item.data_registro,
      nu_cellphone: item.responsavel_celular,
      nu_edition_year: parseInt(item.numero_edicao_1),
      nu_edition: parseInt(item.numero_edicao),
      nu_pages: parseInt(item.paginas),
      nu_phone: item.responsavel_telefone,
    }

    newDeedDTO.push(newDeed)
  })

  return newDeedDTO
}
