import http from '@/api/http'

import {
  TPostAuthGovBrParams,
  TPostAuthGovBrResponse,
  TGetAuthGovBrGetUrlResponse,
} from './types'

export const postAuthGovBr = async (params: TPostAuthGovBrParams) => {
  const response = await http.post<TPostAuthGovBrResponse>(
    '/auth/govbr/',
    params
  )

  return response.data
}

export const getAuthGovBrGetUrl = async () => {
  const response = await http.get<TGetAuthGovBrGetUrlResponse>(
    '/auth/govbr/get-url/'
  )

  return response.data
}
