import React, { useEffect, useMemo, useState } from 'react'

import {
  Box,
  Flex,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import Pagination from '@/components/Pagination'

import { ICardPermissionsProps, IPermissionItem, TPermissions } from './types'

const scopeRadioOptions = [
  {
    value: 'blocked',
    label: 'Bloqueada',
  },
  {
    value: 'total',
    label: 'Total',
  },
  {
    value: 'activeDistribution',
    label: 'Por distribuição ativa',
  },
]
const modeRadioOptions = [
  {
    value: 'edition',
    label: 'Edição',
  },
  {
    value: 'read',
    label: 'Leitura',
  },
]

interface IPageInfo {
  page: number
  itemsPerPage: number
  pageCount: number
  itemsCount: number
}

const CardPermissions = ({ tabItems, onChange }: ICardPermissionsProps) => {
  const [permissions, setPermissions] = useState<TPermissions | null>(null)
  const [paginationInfo, setPaginationInfo] = useState<
    Record<string, IPageInfo>
  >({})
  const itemsPaginated = useMemo(() => {
    const items: Record<string, Array<IPermissionItem>> = {}

    tabItems.forEach(tab => {
      const permissionsTab = tab.permissions || []
      const { page, itemsPerPage } = paginationInfo[tab.id] || {}

      items[tab.id] = permissionsTab.slice(
        page * itemsPerPage,
        page * itemsPerPage + itemsPerPage
      )
    })

    return items
  }, [permissions, paginationInfo])

  useEffect(() => {
    const permissionsByTab: TPermissions = {}
    tabItems.forEach(tab => {
      permissionsByTab[tab.id] = {}
      ;(tab.permissions || []).forEach(permission => {
        permissionsByTab[tab.id][permission.key] = {
          scope: '',
          mode: '',
        }
      })

      const itemsPerPage = 5
      const paginationTab = {
        page: 0,
        itemsPerPage,
        pageCount: Math.ceil((tab.permissions || []).length / itemsPerPage),
        itemsCount: (tab.permissions || []).length,
      }

      setPaginationInfo(prevState => ({
        ...prevState,
        [tab.id]: paginationTab,
      }))
    })
    setPermissions(permissionsByTab)
  }, [])

  useEffect(() => {
    if (permissions) {
      onChange?.(permissions)
    }
  }, [permissions])

  return (
    <Stack gap={4}>
      <Tabs variant="soft-rounded" size="sm">
        <TabList gap={1}>
          {tabItems.map(tab => (
            <Tab
              key={`tab-${tab.id}`}
              color="gray.600"
              fontSize="sm"
              _selected={{ bg: '#D4E5FF', color: '#0C326F' }}
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabItems.map(tab => (
            <TabPanel key={`tab-panel-${tab.id}`} px={0}>
              <DataFilter
                testId="data-filter"
                canDownload={false}
                canPrint={false}
                onlySearch
                filters={[]}
                onChangeFilter={() => {}}
              />
              <Stack>
                {itemsPaginated[tab.id].map(permission => (
                  <Box
                    key={`permission-${permission.title}`}
                    py="0.625rem"
                    borderTop="1px solid"
                    borderTopColor="gray.100"
                  >
                    <HStack gap={8} color="brand.neutral.dark_2">
                      <Stack flex={1}>
                        <Text fontWeight="bold">{permission.title}</Text>
                        <Text fontWeight="normal" fontSize="sm">
                          {permission.description}
                        </Text>
                      </Stack>
                      <HStack flex={1} gap={8}>
                        <Stack>
                          <Text fontSize="sm" fontWeight="semibold">
                            Abrangência
                          </Text>
                          <Text fontSize="sm" fontWeight="semibold">
                            Modo
                          </Text>
                        </Stack>
                        <Stack>
                          <RadioGroup
                            name="scope"
                            size="lg"
                            isDisabled={permission.scopeBlocked}
                          >
                            <Flex gap={6}>
                              {scopeRadioOptions.map(option => (
                                <Radio
                                  key={`scope-radio-${option.value}`}
                                  value={option.value}
                                  _checked={{
                                    bg: 'brand.primary.dark_1',
                                  }}
                                  onChange={e => {
                                    const value = e.target.value
                                    setPermissions(prevState => ({
                                      ...prevState,
                                      [tab.id]: {
                                        ...prevState?.[tab.id],
                                        [permission.key]: {
                                          ...prevState?.[tab.id][
                                            permission.key
                                          ],
                                          scope: value,
                                        },
                                      },
                                    }))
                                  }}
                                >
                                  <Text fontSize="sm">{option.label}</Text>
                                </Radio>
                              ))}
                            </Flex>
                          </RadioGroup>
                          <RadioGroup
                            name="mode"
                            size="lg"
                            isDisabled={permission.modeBlocked}
                          >
                            <Flex gap={6}>
                              {modeRadioOptions.map(option => (
                                <Radio
                                  key={`mode-radio-${option.value}`}
                                  value={option.value}
                                  _checked={{
                                    bg: 'brand.primary.dark_1',
                                  }}
                                  onChange={e => {
                                    const value = e.target.value
                                    setPermissions(prevState => ({
                                      ...prevState,
                                      [tab.id]: {
                                        ...prevState?.[tab.id],
                                        [permission.key]: {
                                          ...prevState?.[tab.id][
                                            permission.key
                                          ],
                                          mode: value,
                                        },
                                      },
                                    }))
                                  }}
                                >
                                  <Text fontSize="sm">{option.label}</Text>
                                </Radio>
                              ))}
                            </Flex>
                          </RadioGroup>
                        </Stack>
                      </HStack>
                    </HStack>
                  </Box>
                ))}
              </Stack>
              <Pagination
                itemsCount={paginationInfo[tab.id]?.itemsCount}
                pageCount={paginationInfo[tab.id]?.pageCount}
                itemsPerPage={paginationInfo[tab.id]?.itemsPerPage}
                optionsItemsPerPage={[5, 10, 15]}
                onChangePageInfo={({ pageIndex, itemsPerPage }) => {
                  setPaginationInfo(prevState => ({
                    ...prevState,
                    [tab.id]: {
                      ...prevState[tab.id],
                      page: pageIndex,
                      itemsPerPage,
                    },
                  }))
                }}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default CardPermissions
