import React, { useMemo, useEffect } from 'react'

import {
  Box,
  Flex,
  useColorModeValue,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'

import { useSidebar } from '@/contexts/SidebarContext'

import MobileNav from './parts/MobileNav'
import SidebarContent from './parts/SibebarContent'
import { ISidebarProps } from './types'

function Sidebar({ linkItems, children }: ISidebarProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isMini, toggleSidebarState } = useSidebar()

  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const sidebarWidth = useMemo(() => (isMini ? 12 : 48), [isMini])

  useEffect(() => {
    if (isMini) {
      toggleSidebarState()
    }
  }, [isMobile])

  return (
    <Box minHeight="100vh" bg={useColorModeValue('white', 'blackAlpha.900')}>
      {!isMobile && (
        <SidebarContent
          onClose={() => onClose}
          toggleSidebar={toggleSidebarState}
          sidebarWidth={sidebarWidth}
          linkItems={linkItems}
        />
      )}
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            toggleSidebar={toggleSidebarState}
            sidebarWidth={sidebarWidth}
            linkItems={linkItems}
          />
        </DrawerContent>
      </Drawer>
      <MobileNav
        display={{ base: 'flex', md: 'none' }}
        onOpen={onOpen}
        sidebarWidth={sidebarWidth}
      />
      <Flex
        flexDirection="column"
        minHeight="100vh"
        ml={{ base: 0, md: sidebarWidth }}
        p="12"
        pt="6"
      >
        {children}
      </Flex>
    </Box>
  )
}

export default Sidebar
