import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Box, Text, Link, Flex } from '@chakra-ui/react'

import { IBreadcrumbsProps } from './types'

const Breadcrumbs: React.FC<IBreadcrumbsProps> = ({ items }) => {
  const lastItemIndex = items.length - 1
  const currentItem = lastItemIndex >= 0 ? items[lastItemIndex].label : null

  const crumbColor = 'brand.neutral.dark_2'
  const selectedCrumbColor = 'brand.primary.dark_1'

  return (
    <Flex align="flex-start" gap={2}>
      {items.map((item, index) => (
        <Box key={`breadcrum-item-${index}`}>
          <Link
            as={ReactRouterLink}
            to={item.href || '#'}
            color={item.label === currentItem ? selectedCrumbColor : crumbColor}
            fontFamily="Roboto"
            fontSize="md"
            fontWeight="normal"
            lineHeight="150%"
            marginRight={index < items.length - 1 ? 2 : '0'}
          >
            {item.label}
          </Link>
          {index < items.length - 1 && <Text as="span">/</Text>}
        </Box>
      ))}
    </Flex>
  )
}

export default Breadcrumbs
