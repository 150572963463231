import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusCardTeamProps, IStatusCardTeamTagStyles } from './types'

const styles: IStatusCardTeamTagStyles = {
  active: {
    name: 'Ativo',
    backgroundColor: '#E3F5E1',
    color: '#168821',
  },
  inactive: {
    name: 'Inativo',
    backgroundColor: '#FFF3F2',
    color: '#E60000',
  },
  finished: {
    name: 'Finalizado',
    backgroundColor: '#EDF5FF',
    color: 'brand.primary.dark_1',
  },
  waiting_works: {
    name: 'Aguardando Obras',
    backgroundColor: '#FFF3EA',
    color: '#FF580A',
  },
  waiting_members: {
    name: 'Aguardando Membros',
    backgroundColor: '#FFF3EA',
    color: '#FF580A',
  },
  waiting_objects: {
    name: 'Aguardando Objetos',
    backgroundColor: '#FFF3EA',
    color: '#FF580A',
  },
  pending: {
    name: 'Pendente',
    backgroundColor: '#F8F8F8',
    color: '#333333',
  },
}

function StatusTag({ tag, ...rest }: IStatusCardTeamProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.pending

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
