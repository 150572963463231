import React from 'react'

import { Text, Stack, Flex, Box } from '@chakra-ui/react'

import { ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'

import Overview from './Overview'
import { exampleData } from './Overview/exampleData'
import Phase1 from './Phases/1'
import Phase2 from './Phases/2'
import Phase3 from './Phases/3'
import PhaseNotStarted from './Phases/NotStarted'

export const steps = [
  {
    title: 'Fase 1',
    description: (
      <>
        Validação de Inscrição
        <Flex py="1">
          <Tag group="statusObjects" value="finished_green" />{' '}
        </Flex>
      </>
    ),
  },
  {
    title: 'Fase 2',
    description: (
      <>
        Avaliação Pedagógica
        <Flex py="1">
          <Tag group="statusObjects" value="external_phase" />{' '}
        </Flex>
      </>
    ),
  },
  {
    title: 'Fase 3',
    description: (
      <>
        Análise de Atributos
        <Flex py="1">
          <Tag group="statusObjects" value="ongoing" />{' '}
        </Flex>
      </>
    ),
  },
  {
    title: 'Fase 4',
    description: (
      <>
        Acessibilidade
        <Flex py="1">
          <Tag group="statusObjects" value="not_started" />{' '}
        </Flex>
      </>
    ),
  },
  {
    title: 'Fase 5',
    description: (
      <>
        Insumos de Qualificação
        <Flex py="1">
          <Tag group="statusObjects" value="not_started" />{' '}
        </Flex>
      </>
    ),
  },
]

export const tabs: ITabItem[] = [
  {
    id: 'visao-geral',
    label: 'Visão Geral',
    render: () => <Overview details={exampleData()} />,
  },
  {
    id: 'fase1',
    label: 'Fase 1',
    render: () => <Phase1 />,
  },
  {
    id: 'fase2',
    label: 'Fase 2',
    render: () => {
      return <Phase2 />
    },
  },
  {
    id: 'fase3',
    label: 'Fase 3',
    render: () => {
      return <Phase3 />
    },
  },
  {
    id: 'fase4',
    label: 'Fase 4',
    render: () => {
      return <PhaseNotStarted phaseName="Acessibilidade" IsNextPhase={true} />
    },
  },
  {
    id: 'fase5',
    label: 'Fase 5',
    render: () => {
      return <PhaseNotStarted phaseName="Insumos de Qualificação" />
    },
  },
]
