import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { postDeeds } from './services'
import { TPostDeedsParams, TPostDeedsResponse } from './types'

export const useMutationPostDeeds = (
  options?: UseMutationOptions<
    TPostDeedsResponse,
    AxiosError<IErrorDTO>,
    TPostDeedsParams
  >
) => {
  return useMutation(postDeeds, options)
}
