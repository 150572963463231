import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getObjects } from './services'
import { TGetObjectsResponse } from './types'

export const useQueryGetObjects = (
  options?: UseQueryOptions<TGetObjectsResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getObjects()

  return useQuery<TGetObjectsResponse, AxiosError<IErrorDTO>>(
    useQueryGetObjects.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryGetObjects.getFullQueryKey = (): QueryKey => ['objects']

useQueryGetObjects.queryKey = ['objects']
