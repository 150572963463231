import React from 'react'
import { FaFile, FaQuestionCircle, FaUser } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Stack, Text, Flex, Center, Icon, Button } from '@chakra-ui/react'

const Choice = () => {
  const navigate = useNavigate()

  return (
    <Center flex={1}>
      <Stack gap={8} color="brand.primary.dark_1" align="center">
        <Icon as={FaQuestionCircle} boxSize={32} />
        <Text fontSize="xl" fontWeight="bold">
          Como deseja redistribuir uma nova equipe?
        </Text>
        <Flex gap={4}>
          <Button leftIcon={<FaUser />} onClick={() => navigate('por-membro')}>
            Por membro
          </Button>
          <Button leftIcon={<FaFile />} onClick={() => navigate('por-volume')}>
            Por volume
          </Button>
        </Flex>
      </Stack>
    </Center>
  )
}

export default Choice
