import React from 'react'
import { NavLink } from 'react-router-dom'

import { Icon, useColorModeValue } from '@chakra-ui/react'

import { useSidebar } from '@/contexts/SidebarContext'

import S from '../../styles'
import { INavItemProps } from '../../types'

const NavItem = ({ onClose, name, icon, to, ...rest }: INavItemProps) => {
  const { isMini } = useSidebar()

  return (
    <S.NavItem
      as={NavLink}
      to={to}
      onClick={onClose}
      role="group"
      _activeLink={{
        color: 'brand.highlight.dark',
        fontWeight: '700',
        borderBottomColor: 'brand.highlight.dark',
      }}
      _hover={{
        bg: 'brand.highlight.dark',
        color: 'brand.neutral.white',
        fontWeight: '700',
        borderBottomColor: 'brand.highlight.dark',
      }}
      borderBottomColor="brand.primary.dark_2"
      {...rest}
    >
      {icon && <Icon mr="4" fontSize="24" as={icon} />}
      {!isMini ? name : ''}
    </S.NavItem>
  )
}

export default NavItem
