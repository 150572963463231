import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { postAuthLogout } from './services'
import { TPostAuthLogoutResponse } from './types'

export const useMutationPostAuthLogout = (
  options?: UseMutationOptions<TPostAuthLogoutResponse, AxiosError<IErrorDTO>>
) => {
  return useMutation(postAuthLogout, options)
}
