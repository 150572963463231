const theme = {
  primary: {
    base: '#8C93A4',
    dark_1: '#465671',
    dark_2: '#23415B',
    light_1: '#B3B7C1',
    light_2: '#DDE1E5',
  },
  highlight: {
    base: '#DDA253',
    dark: '#D27723',
    dark_2: '#DD6B20',
    light: '#E9DEB4',
    light_2: '#FEEBC8',
  },
  neutral: {
    base: '#888888',
    white: '#FFFFFF',
    dark_1: '#636363',
    dark_2: '#333333',
    light_1: '#CCCCCC',
    light_2: '#F8F8F8',
  },
  success: {
    base: '#168821',
    dark: '#154C21',
    light: '#E3F5E1',
  },
  error: {
    base: '#E60000',
    dark: '#8B0A03',
    light: '#FFF3F2',
  },
}

export default {
  brand: {
    ...theme,
  },
}
