import React from 'react'
import {
  FaChevronLeft,
  FaCheckCircle,
  FaChevronCircleRight,
} from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Formik } from 'formik'

import {
  Stack,
  Text,
  Flex,
  Button,
  SimpleGrid,
  useDisclosure,
  Box,
} from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import Modal from '@/components/Modal'
import Tabs from '@/components/Tabs'
import { ITabInfo } from '@/components/Tabs/types'

import TeamTab from './TeamTab'
import VolumesTable from './VolumesTables'

const TeamForm = ({
  co_team,
  num_analists = 1,
}: {
  co_team: number
  num_analists?: number
}) => {
  return (
    <SimpleGrid columns={2} spacing={4}>
      <Input name={`team_${co_team}-supervisor`} label="Supervisor" />
      {[...Array(num_analists)].map((_, index) => (
        <Input
          key={index}
          name={`team_${co_team}-analist_${index}`}
          label="Analista"
        />
      ))}
    </SimpleGrid>
  )
}

const ByVolume = () => {
  const navigate = useNavigate()
  const [tabIndex, setTabIndex] = React.useState(0)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const accordionItems = [
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
      tag: 'Teste',
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
    {
      title: 'Código da coleção',
      description: 'Nome da editora',
      children: <VolumesTable />,
    },
  ]

  const tabsItems = Array.from({ length: 9 }).map((_, index) => ({
    id: `tab-${index}`,
    label: `EQ00${index + 1}`,
    render: () => <TeamTab accordionItems={accordionItems} />,
  }))

  const handleConfirmSave = () => {
    // TODO: Do something
    onClose()
  }

  return (
    <Stack gap={6} flex={1} justify="space-between">
      <Stack gap={4}>
        <Stack gap={2}>
          <Text fontSize="xl" color="brand.primary.dark_1" fontWeight="bold">
            Por volume
          </Text>
          <Text fontSize="xs">
            Ao trocar o membro, só as atividades do volume em questão serão
            redistribuídas para o novo membro.
          </Text>
        </Stack>
        <Box>
          <Tabs
            index={tabIndex}
            items={tabsItems}
            variant="rounded"
            size="sm"
            onTabClick={({ index }: ITabInfo) => {
              setTabIndex(index)
            }}
          />
        </Box>
      </Stack>
      <Flex justify="space-between">
        <Button
          variant="ghost"
          leftIcon={<FaChevronLeft />}
          onClick={() => navigate('/objetos/redistribuicao-times')}
        >
          Sair sem salvar
        </Button>
        {tabIndex !== tabsItems.length - 1 ? (
          <Button
            size="sm"
            leftIcon={<FaChevronCircleRight />}
            isDisabled={tabIndex === tabsItems.length - 1}
            onClick={() => setTabIndex(tabIndex + 1)}
          >
            Próxima equipe
          </Button>
        ) : (
          <Button
            variant="success"
            leftIcon={<FaCheckCircle />}
            onClick={onOpen}
          >
            Salvar nova distribuição
          </Button>
        )}
      </Flex>
      <Modal
        title="Deseja finalizar a redistribuição dos equipes para esta fase?"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <Flex justify="flex-end" gap={4}>
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Não, voltar a redistribuição
          </Button>
          <Button borderRadius="full" onClick={handleConfirmSave}>
            Sim, desejo finalizar
          </Button>
        </Flex>
      </Modal>
    </Stack>
  )
}

export default ByVolume
