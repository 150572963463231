import { styled } from '@chakra-ui/react'
import { Box, Flex, Button, Text, FormLabel } from '@chakra-ui/react'

const Header = styled(Box, {
  baseStyle: {
    width: '100%',
    height: '68px',
    background: '#FFFFFF',
    color: '#7633B1',
    paddingY: 4,
    display: 'flex',
    justifyContent: 'center',
  },
})

const HeaderContent = styled(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '950px',
    flex: 1,
    paddingX: { base: 4, sm: 8 },
  },
})

const Main = styled(Flex, {
  baseStyle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginY: 8,
  },
})

const MainBox = styled(Box, {
  baseStyle: {
    marginX: 8,
    display: 'flex',
    flexDirection: 'column',
    padding: {
      base: 8,
      sm: 16,
    },
    gap: 16,
    borderRadius: '0.625rem',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
})

const Title = styled(Text, {
  baseStyle: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '120%',
  },
})

const Subtitle = styled(Text, {
  baseStyle: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '150%',
  },
})

const MainButton = styled(Button, {
  baseStyle: {
    color: 'white',
    borderRadius: '2.5rem',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    paddingX: '24px',
  },
})

const Footer = styled(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 8,
    paddingBottom: 8,
  },
})

const UserPictureBox = styled(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
})

const FormTitle = styled(Text, {
  baseStyle: {
    color: '#2B0C47',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '120%',
  },
})

const InputLabel = styled(FormLabel, {
  baseStyle: {
    color: '#2D3748',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '150%',
  },
})

const ActionBox = styled(Box, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
  },
})

export default {
  Header,
  HeaderContent,
  Main,
  MainBox,
  Title,
  Subtitle,
  MainButton,
  Footer,
  UserPictureBox,
  InputLabel,
  FormTitle,
  ActionBox,
}
