import http from '@/api/http'

import { TPostDeedsParams, TPostDeedsResponse } from './types'

export const postDeeds = async (params: TPostDeedsParams) => {
  const response = await http.post<TPostDeedsResponse>(
    '/deeds/deed_import/',
    params
  )

  return response.data
}
