import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import * as AxiosLogger from 'axios-logger'

import { IErrorDTO } from '@/DTOs/errorDTO'

const http = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  })
)

AxiosLogger.setGlobalConfig({
  data: false,
  method: true,
  url: true,
  status: true,
  statusText: true,
})

http.interceptors.request.use((request: InternalAxiosRequestConfig) => {
  return AxiosLogger.requestLogger(request) as InternalAxiosRequestConfig
}, AxiosLogger.errorLogger)

http.interceptors.response.use(
  (response: AxiosResponse) => {
    return AxiosLogger.responseLogger(response) as AxiosResponse
  },
  error => {
    if (error.response) {
      const path = error.response.config.url
      const status = error.response.status

      if (status === 401 && !path?.includes('/login')) {
        const errorResponse = error.response.data as IErrorDTO
        const errors = errorResponse.errors.map(error => error.code)

        if (errors.includes('not_authenticated')) {
          window.location.href = '/login'
        }
      }

      return AxiosLogger.errorLogger(error)
    }

    return Promise.reject(error)
  }
)

export default http
