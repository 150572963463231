import React from 'react'
import { FaFileExport } from 'react-icons/fa6'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

import Tabs from '@/components/Tabs'
import Tag from '@/components/Tag'

import { tabs } from './controller'

const Phase1: React.FC = () => {
  const [activeTabId, setActiveTabId] = React.useState('volumes')
  return (
    <Flex direction="column" gap={8}>
      <Flex direction="row" justify="space-between">
        <Flex direction="column" gap={1}>
          <Text
            color="#475671"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Avaliação Pedagógica
          </Text>
          <Box>
            <Tag group="statusObjects" value="finished_green" />
          </Box>
        </Flex>
        <Flex>
          <Button
            colorScheme="gray"
            variant="outline"
            borderRadius="4"
            leftIcon={<Icon as={FaFileExport} color="#475671" />}
          >
            <Text color="#475671">Enviar Relatório</Text>
          </Button>
        </Flex>
      </Flex>
      <Flex>
        <Tabs
          items={tabs}
          initialTab={activeTabId}
          onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
          variant="soft-rounded"
          size="sm"
          colorScheme="gray"
        />
      </Flex>
    </Flex>
  )
}

export default Phase1
