/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { BsFolderX, BsFolderPlus } from 'react-icons/bs'

import { Box, Button, Flex, useToast } from '@chakra-ui/react'

import { useQueryUser } from '@/api/user/management/queries'
import { formatDate, reverseDate } from '@/utils/date'

import RelinkModal from '../parts/RelinkModal'
import { IUserDetailsProps } from '../types'
import S from './styles'

const BondsForm: React.FC<IUserDetailsProps> = ({ userId }) => {
  const [isRelinkModalOpen, setRelinkModalOpen] = useState(false)
  const [isUnlinkModalOpen, setUnlinkModalOpen] = useState(false)

  const toast = useToast()

  const handleOpenRelinkModal = () => {
    setRelinkModalOpen(true)
  }

  const handleCloseRelinkModal = () => {
    setRelinkModalOpen(false)
  }

  const handleOpenUnlinkModal = () => {
    setUnlinkModalOpen(true)
  }

  const handleCloseUnlinkModal = () => {
    setUnlinkModalOpen(false)
  }

  const {
    data: userData,
    refetch,
    isLoading,
    isError,
  } = useQueryUser(
    { coUser: userId },
    {
      enabled: !!userId,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados do usuário',
          description: 'Não foi possível obter os dados do usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const isAdmin = userData?.ncoProfile[0] === 1

  const endDate =
    !userData?.dtJobBindEnd && userData?.dtRenewal
      ? reverseDate(formatDate(userData.dtRenewal))
      : ''

  const isUnlinkDisabled = !!userData?.dtJobBindEnd || isAdmin
  const hasRenewal = !!userData?.dtRenewal
  const isActive = userData?.dsStatus === 'Ativo'

  return (
    <Box width="100%">
      <S.Row>
        <S.HeaderText color="brand.primary.dark_1">
          Dados da Instituição
        </S.HeaderText>
      </S.Row>
      <S.Row>
        <S.InstitutionColumn>
          <S.StyledFormLabel>
            <S.LabelText color="'gray.700'">Instituição</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            placeholder="Digite o nome da instituição"
            value={userData?.dsInstitution || ''}
          />
        </S.InstitutionColumn>
        <S.PositionColumn>
          <S.StyledFormLabel>
            <S.LabelText color="'gray.700'">Cargo</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            placeholder="Digite o cargo"
            value={userData?.dsRolePositionJob || ''}
          />
        </S.PositionColumn>
      </S.Row>

      <S.Row>
        <S.HeaderText color="brand.primary.dark_1">
          Dados de Vínculo
        </S.HeaderText>
      </S.Row>

      <Flex gap={4} align="flex-end">
        <Flex direction="column">
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Data de Inicio</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="date"
            value={userData?.dtJobBindStart || ''}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
        </Flex>
        <Flex direction="column">
          <S.StyledFormLabel>
            <S.LabelText color="gray.700">Previsão de Fim</S.LabelText>
          </S.StyledFormLabel>
          <S.StyledInput
            variant="filled"
            type="date"
            value={endDate}
            readOnly={isAdmin}
            disabled={isAdmin}
          />
        </Flex>
        {!isAdmin && (
          <>
            <Button
              w="full"
              bg="brand.error.base"
              textColor="white"
              px={4}
              leftIcon={<BsFolderX />}
              disabled={!!userData?.dtJobBindEnd || isActive}
              onClick={handleOpenUnlinkModal}
              _hover={{
                bg: 'brand.error.dark',
              }}
            >
              Desvincular
            </Button>
            <Button
              w="full"
              bg="brand.success.base"
              textColor="white"
              leftIcon={<BsFolderPlus />}
              onClick={() => setRelinkModalOpen(true)}
              _hover={{
                bg: 'brand.success.dark',
              }}
            >
              {hasRenewal ? 'Renovar vínculo' : 'Vincular'}
            </Button>
          </>
        )}
        <RelinkModal
          isOpen={isRelinkModalOpen}
          onClose={handleCloseRelinkModal}
          users={userData ? [{ ...userData, coUser: [userData.coUser] }] : []}
          refetchUsers={refetch}
        />
      </Flex>
    </Box>
  )
}

export default BondsForm
