import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getNoticeObject } from './services'
import { TGetNoticeObjectResponse } from './types'

export const useQueryGetNoticeObjects = (
  options?: UseQueryOptions<TGetNoticeObjectResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getNoticeObject()

  return useQuery<TGetNoticeObjectResponse, AxiosError<IErrorDTO>>(
    useQueryGetNoticeObjects.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryGetNoticeObjects.getFullQueryKey = (): QueryKey => ['co_notice']

useQueryGetNoticeObjects.queryKey = ['co_notice']
