import React, { useState } from 'react'
import { FaRegEdit, FaTrashAlt } from 'react-icons/fa'

import { Stack, Text, Flex, IconButton } from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

interface IInfoProps {
  title: string
  subtitle: string
}

const Info = ({ title, subtitle }: IInfoProps) => {
  return (
    <Stack gap={0.5} color="brand.neutral.dark_2">
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="xs">{subtitle}</Text>
    </Stack>
  )
}

const Actions = () => {
  return (
    <Flex justify="space-between">
      <IconButton variant="ghost" aria-label="Editar" icon={<FaRegEdit />} />
      <IconButton variant="ghost" aria-label="Deletar" icon={<FaTrashAlt />} />
    </Flex>
  )
}

const Data = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 20,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const tableHeaders: Array<IHeader> = [
    {
      name: 'Fomulário',
      key: 'checklist',
      type: 'element',
      width: '40%',
    },
    {
      name: 'Objeto',
      key: 'object',
      type: 'element',
      width: '25%',
    },
    {
      name: 'Tipo',
      key: 'type',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Ações',
      key: 'actions',
      type: 'element',
      width: '15%',
      align: 'center',
    },
  ]

  const tableData = [
    {
      checklist: <Info title="Nome do Formulário" subtitle="ID FORMULÁRIO" />,
      object: <Info title="Nome do Objeto" subtitle="ID objeto" />,
      type: 'Tipo 1',
      actions: <Actions />,
    },
  ]

  const filters: Array<IFilter> = [
    {
      name: 'object',
      label: 'Objeto',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'checklist_type',
      label: 'Tipo de formulário',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
  ]

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  return (
    <Stack gap={8}>
      <DataFilter
        testId="data-filter"
        canDownload={false}
        canPrint={false}
        onlySearch
        filters={filters}
        onChangeFilter={() => {}}
      />
      <DataTable headers={tableHeaders} data={tableData} />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Stack>
  )
}

export default Data
