export const exampleData = () => {
  const data = []

  for (let i = 0; i < 20; i++) {
    data.push({
      volumeCode: `Volume ${i}1234 5678 8910 1112`,
      team: `EQ0${i}`,
      status: 'waiting',
    })
  }

  return data
}
