import moment from 'moment'

/**
 * @description Function to convert a timestamp string to a date string
 * @param {string} [timestamp] - The timestamp to be converted.
 * @returns {string} A date string.
 */
export const timestampToDate = (timestamp: string): string => {
  return moment(timestamp).format('DD/MM/YYYY')
}

/**
 * @description Function to convert string env to boolean
 * @param {string} [env] - The env to be converted.
 * @returns {boolean} A boolean value.
 */
export const stringToBoolean = (env?: string): boolean => {
  return env ? env.toLowerCase() === 'true' : false
}
