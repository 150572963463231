import React from 'react'
import { BsPower } from 'react-icons/bs'
import { FaBell, FaPowerOff } from 'react-icons/fa'
import { IoMdMenu } from 'react-icons/io'
import { IoSettingsSharp } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  CloseButton,
  Flex,
  useColorModeValue,
  Image,
  Avatar,
  useMediaQuery,
  Button,
  useToast,
  Icon,
} from '@chakra-ui/react'

import { useMutationPostAuthLogout } from '@/api/auth/logout/mutations'
import LogoFNDE from '@/assets/img/fnde/logo/logo-fnde-a-d-nm.svg'
import MiniLogoSARE from '@/assets/img/sare/faicon/16px/faicon-sare-16px-nd-m-BB.svg'
import LogoSARE from '@/assets/img/sare/logo/16px/horizontal/logo-sare-16px-h-b-nd-m-BB.svg'
import { useSidebar } from '@/contexts/SidebarContext'
import { getErrorDetails } from '@/utils/error'

import S from '../../styles'
import { ISidebarContentProps } from '../../types'
import HeaderNavItem from '../HeaderNavItem'
import NavItem from '../NavItem'

const SidebarContent = ({
  onClose,
  toggleSidebar,
  sidebarWidth,
  linkItems,
  ...rest
}: ISidebarContentProps) => {
  const toast = useToast()
  const navigate = useNavigate()
  const { isMini } = useSidebar()
  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const { mutate: logout, isLoading: isLoadingLogout } =
    useMutationPostAuthLogout({
      onSuccess: () => {
        navigate('/login')
      },
      onError: error => {
        toast({
          status: 'error',
          title: 'Ops! Ocorreu um erro',
          description: getErrorDetails(error),
          isClosable: true,
        })
      },
    })

  const handleLogout = () => {
    logout()
  }

  return (
    <Flex
      direction="column"
      justify="space-between"
      color="white"
      borderRight="1px"
      bg="brand.neutral.light_2"
      borderRightColor={useColorModeValue('white', 'black')}
      paddingBottom={6}
      w={{ base: 'full', md: sidebarWidth }}
      pos="fixed"
      h="full"
      transition="width 0.25s"
      {...rest}
    >
      <Box>
        <Box bg={useColorModeValue('brand.highlight.dark', 'black')}>
          <Flex
            alignItems="center"
            py={{ base: 4, md: isMini ? 3 : 6 }}
            px={{ base: 8, md: 0 }}
            justify={{ base: 'space-between', md: 'center' }}
          >
            <Image
              height={{ base: '36px', sm: isMini ? '24px' : '48px' }}
              src={isMini ? MiniLogoSARE : LogoSARE}
              alt="Logo SARE"
            />
            {isMobile && <CloseButton onClick={onClose} />}
          </Flex>
          <Flex direction={isMini ? 'column' : 'row'} align="center">
            <HeaderNavItem to="/perfil" onClose={onClose}>
              <Avatar size="xs" />
            </HeaderNavItem>
            {!isMini && (
              <HeaderNavItem to="/configuracoes" onClose={onClose}>
                <IoSettingsSharp size={24} />
              </HeaderNavItem>
            )}
            {!isMini && (
              <HeaderNavItem to="/painel/notificacoes" onClose={onClose}>
                <FaBell size={24} />
              </HeaderNavItem>
            )}
            {!isMobile && (
              <S.HeaderItem
                color="brand.highlight.light"
                variant="ghost"
                _hover={{
                  color: 'brand.highlight.dark',
                  bg: 'brand.neutral.light_2',
                }}
                onClick={toggleSidebar}
              >
                <IoMdMenu size={24} />
              </S.HeaderItem>
            )}
          </Flex>
        </Box>
        <Box color="brand.primary.dark_2">
          {linkItems.map(link => (
            <NavItem
              key={link.name}
              name={link.name}
              icon={link.icon}
              to={link.to}
              onClose={onClose}
            />
          ))}
          <Box>
            <Button
              w="full"
              variant="ghost"
              color="brand.primary.dark_2"
              isLoading={isLoadingLogout}
              onClick={handleLogout}
            >
              <Flex gap={2}>
                <Flex align="center">
                  <Icon as={FaPowerOff} boxSize={isMini ? 6 : 4} />
                </Flex>
                {!isMini && 'Sair'}
              </Flex>
            </Button>
          </Box>
        </Box>
      </Box>
      <Image src={LogoFNDE} height="60px" />
    </Flex>
  )
}

export default SidebarContent
