import React, { useEffect, useMemo, useRef } from 'react'
import { BsChevronLeft, BsCheckCircle } from 'react-icons/bs'
import { useNavigate, useParams } from 'react-router-dom'

import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
} from 'formik'
import * as yup from 'yup'

import {
  Box,
  Stack,
  SimpleGrid,
  Input,
  FormControl,
  FormLabel,
  Text,
  Textarea,
  Flex,
  Button,
  useToast,
  Skeleton,
} from '@chakra-ui/react'

import { useMutationUpdatePermissionsGroup } from '@/api/user/permissions/mutations'
import { useQueryPermissionsGroup } from '@/api/user/permissions/queries'
import CardPermissions from '@/components/Cards/Permissions'
import Select from '@/components/Select'

import { ACTIVE, INACTIVE, tabItemsPermissions } from './data'
import { TPermissionFormValues } from './types'

const Permissions = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { permissionId } = useParams()

  const { data: permissionGroup, isLoading: isPermissionLoading } =
    useQueryPermissionsGroup(
      {
        id: String(permissionId),
      },
      {
        enabled: !!permissionId,
        onError: () => {
          toast({
            title: 'Houve um erro ao carregar os dados da permissão.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
      }
    )

  const { mutate: updatePermissionsGroup, isLoading: isUpdating } =
    useMutationUpdatePermissionsGroup({
      onSuccess: () => {
        toast({
          title: 'Grupo de permissões atualizadas com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
        navigate('/configuracoes/permissoes')
      },
      onError: () => {
        toast({
          title: 'Houve um erro ao atualizar o grupo de permissões',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })

  const permissionGroupData = useMemo(() => {
    return {
      id: permissionGroup?.id,
      name: permissionGroup?.name,
      description: permissionGroup?.description,
      status: permissionGroup?.dsStatus,
    }
  }, [permissionGroup])

  const formikRef = useRef<FormikProps<TPermissionFormValues>>(null)

  const validationSchema = yup.object({
    name: yup.string().required('Nome é obrigatório'),
    status: yup.object().required('Status é obrigatório'),
    description: yup.string().required('Descrição é obrigatória'),
    // level: yup.object().required('Nível de acesso é obrigatório'),
    // phases: yup.array().length(1, 'Selecione pelo menos uma fase'),
  })

  const handleUpdatePermissionData = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit()
    }
  }

  const onSubmit = (values: TPermissionFormValues) => {
    const payload = {
      id: String(permissionId),
      name: values.name,
      description: values.description,
      dsStatus: values.status === ACTIVE,
      permissions: [],
    }

    updatePermissionsGroup(payload)
  }

  return (
    <Stack gap={6}>
      <Skeleton isLoaded={!isPermissionLoading} borderRadius="base">
        <Box p={5} boxShadow="md" borderRadius="base">
          <Formik
            innerRef={formikRef}
            initialValues={{
              name: permissionGroupData?.name || '',
              level: null,
              phases: [],
              status: permissionGroupData?.status ? ACTIVE : INACTIVE,
              description: permissionGroupData?.description || '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form>
              <Stack gap={4}>
                <SimpleGrid columns={2} rowGap={4} columnGap={6}>
                  <FormControl>
                    <FormLabel fontSize="sm">
                      Nome do Grupo de Permissões
                    </FormLabel>
                    <Field
                      as={Input}
                      type="name"
                      name="name"
                      placeholder="Digite o nome"
                      variant="filled"
                    />
                    <ErrorMessage name="name">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  {/* <FormControl>
                    <FormLabel fontSize="sm">Nível de Acesso</FormLabel>
                    <Field name="level">
                      {({ field, form }: FieldProps) => (
                        <Select
                          value={field.value}
                          variant="filled"
                          placeholder="Selecione o nível de acesso"
                          isSearchable={false}
                          options={[
                            { value: '1', label: 'Nível 1' },
                            { value: '2', label: 'Nível 2' },
                            { value: '3', label: 'Nível 3' },
                          ]}
                          onChange={option => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="level">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl> */}
                  {/* <FormControl>
                    <FormLabel fontSize="sm">Fases</FormLabel>
                    <Field name="phases">
                      {({ field, form }: FieldProps) => (
                        <Select
                          value={field.value}
                          variant="filled"
                          placeholder="Selecione as fases"
                          isSearchable={false}
                          isMulti
                          options={[
                            { value: '1', label: 'Fase 1' },
                            { value: '2', label: 'Fase 2' },
                            { value: '3', label: 'Fase 3' },
                          ]}
                          onChange={option => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="phases">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl> */}
                  <FormControl>
                    <FormLabel fontSize="sm">Status</FormLabel>
                    <Field name="status">
                      {({ field, form }: FieldProps) => (
                        <Select
                          value={field.value}
                          variant="filled"
                          placeholder="Selecione o status"
                          isSearchable={false}
                          options={[
                            { value: 'active', label: 'Ativo' },
                            { value: 'inactive', label: 'Inativo' },
                          ]}
                          onChange={option => {
                            form.setFieldValue(field.name, option)
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage name="status">
                      {(message: string) => (
                        <Text color="red.600" fontSize="sm">
                          {message}
                        </Text>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </SimpleGrid>
                <FormControl as={Stack}>
                  <FormLabel fontSize="sm">Descrição</FormLabel>
                  <Field
                    as={Textarea}
                    type="text"
                    name="description"
                    placeholder="Digite aqui..."
                    variant="filled"
                    maxH="142px"
                  />
                  <Text fontSize="sm" color="brand.neutral.dark_1">
                    Descreva aqui as principais funções desse grupo de
                    permissões
                  </Text>
                  <ErrorMessage name="description">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Stack>
            </Form>
          </Formik>
        </Box>
      </Skeleton>
      <Skeleton isLoaded={!isPermissionLoading} borderRadius="base">
        <Box p={5} boxShadow="md" borderRadius="base">
          <Stack gap={4}>
            <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
              Configurações de permissões
            </Text>
            <CardPermissions tabItems={tabItemsPermissions} />
            <Flex justifyContent="flex-end" gap="2">
              <Button
                size="sm"
                leftIcon={<BsChevronLeft />}
                variant="ghost"
                fontSize="sm"
                color="brand.primary.dark_1"
                isDisabled={isUpdating}
                onClick={() => navigate('/configuracoes/permissoes')}
              >
                Voltar
              </Button>
              <Button
                size="sm"
                leftIcon={<BsCheckCircle />}
                fontSize="sm"
                color="white"
                bg="brand.primary.dark_1"
                _hover={{
                  bg: 'brand.primary.dark_2',
                }}
                isLoading={isUpdating}
                onClick={handleUpdatePermissionData}
              >
                Salvar alterações
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Skeleton>
    </Stack>
  )
}

export default Permissions
