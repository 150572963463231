import React from 'react'
import { FaCircleCheck } from 'react-icons/fa6'

import { Flex, Icon, Text } from '@chakra-ui/react'

const Notifications = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="5"
      borderRadius="md"
      boxShadow="md"
      gap="8"
    >
      <Icon as={FaCircleCheck} boxSize="128px" color="#168821" />
      <Text
        color="brand.primary.dark_2"
        textAlign="center"
        fontSize="20px"
        fontWeight="700"
        lineHeight="120%"
      >
        Parabéns!
        <br />
        Você não possui novas notificações
      </Text>
    </Flex>
  )
}

export default Notifications
