import { IHeader } from '@/components/DataTable/types'

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Versão',
    key: 'version',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Exemplar',
    key: 'sample',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Formato',
    key: 'format',
    type: 'element',
    width: 'auto',
  },
]
