import React, { useRef } from 'react'
import { FaChevronLeft, FaChevronCircleRight } from 'react-icons/fa'

import { Formik, FormikProps, FormikValues } from 'formik'
import * as yup from 'yup'

import { Stack, Text, Flex, Button, SimpleGrid } from '@chakra-ui/react'

import Accordion from '@/components/Accordion'
import { Input } from '@/components/Forms'

import { IStepControlProps } from './types'

const TeamForm = ({
  co_team,
  num_analists = 1,
}: {
  co_team: number
  num_analists?: number
}) => {
  return (
    <SimpleGrid columns={2} spacing={4}>
      <Input name={`team_${co_team}-supervisor`} label="Supervisor" />
      {[...Array(num_analists)].map((_, index) => (
        <Input
          key={index}
          name={`team_${co_team}-analist_${index}`}
          label="Analista"
        />
      ))}
    </SimpleGrid>
  )
}

const AssembleTeams = ({ goToNext }: IStepControlProps) => {
  const accordionItems = [
    {
      title: 'EQ001',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={1} />,
      tag: 'Teste',
    },
    {
      title: 'EQ002',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={2} />,
    },
    {
      title: 'EQ003',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={3} />,
    },
    {
      title: 'EQ004',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={4} />,
    },
  ]

  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const handleSubmit = (values: FormikValues) => {
    goToNext?.()
  }

  return (
    <Stack>
      <Stack gap={2}>
        <Text fontSize="xl" color="brand.primary.dark_1" fontWeight="bold">
          Montar Equipes
        </Text>
        <Text fontSize="xs">
          Selecione o supervisor e os analistas para cada equipe desejada:
        </Text>
      </Stack>
      <Stack>
        <Formik innerRef={formikRef} initialValues={{}} onSubmit={handleSubmit}>
          <Accordion items={accordionItems}></Accordion>
        </Formik>
      </Stack>
      <Flex justify="space-between">
        <Button
          size="sm"
          key="go-back"
          variant="ghost"
          leftIcon={<FaChevronLeft />}
          color="brand.primary.dark_1"
        >
          Sair da distribuição
        </Button>
        <Button
          type="submit"
          size="sm"
          bg="brand.primary.dark_1"
          _hover={{ bg: 'brand.primary.dark_2' }}
          color="white"
          leftIcon={<FaChevronCircleRight />}
          onClick={() => {
            if (formikRef.current) {
              formikRef.current.handleSubmit()
            }
          }}
        >
          Próximo
        </Button>
      </Flex>
    </Stack>
  )
}

export default AssembleTeams
