import http from '@/api/http'

import {
  TGetAddressCountriesResponse,
  TGetAddressStatesParams,
  TGetAddressStatesResponse,
  TGetAddressCitiesParams,
  TGetAddressCitiesResponse,
} from './types'

export const getAddressCountries = async () => {
  const response = await http.get<TGetAddressCountriesResponse>(
    '/address/countries/'
  )

  return response.data
}

export const getAddressStates = async (params: TGetAddressStatesParams) => {
  const response = await http.get<TGetAddressStatesResponse>('/address/ufs/', {
    params,
  })

  return response.data
}

export const getAddressCities = async (params: TGetAddressCitiesParams) => {
  const response = await http.get<TGetAddressCitiesResponse>(
    '/address/cities/',
    {
      params,
    }
  )

  return response.data
}
