import React from 'react'
import { FaCircleExclamation } from 'react-icons/fa6'
import { Link } from 'react-router-dom'

import {
  Stack,
  Text,
  Button,
  useColorModeValue,
  Flex,
  Icon,
} from '@chakra-ui/react'

import S from '@/common/Templates/TemplateLogin/styles'

const Layout404: React.FC = () => {
  return (
    <Stack align="center" justify="center" minHeight="100vh">
      <S.MainBox
        maxWidth="596px"
        bg={useColorModeValue('white', 'transparent')}
      >
        <Flex align="center" justify="center" direction="column" gap={10}>
          <Icon
            as={FaCircleExclamation}
            boxSize="128px"
            color={useColorModeValue('brand.error.dark', 'white')}
          />
          <S.Title color={useColorModeValue('brand.error.dark', 'white')}>
            Ops! Página não encontrada.
          </S.Title>
          <S.Subtitle
            color={useColorModeValue('brand.neutral.dark_2', 'white')}
          >
            Parece que você tentou acessar algo que não existe mais ou foi
            movido para outro lugar. Não se preocupe, estamos aqui para ajudar
            você a voltar para o caminho certo!
            <br />
            <br />
            <Text fontSize="md" fontWeight="bold" color="brand.primary.dark_1">
              Verifique se o endereço digitado está correto ou retorne à{' '}
              <Link to="/" style={{ textDecoration: 'underline' }}>
                página inicial
              </Link>{' '}
              e navegue por nossas seções.
            </Text>
            <br />
            Se continuar com problemas, nossa equipe de suporte está sempre à
            disposição para ajudar.
          </S.Subtitle>
          <Link to="https://atendimento.nees.ufal.br/" target="_blank">
            <Button borderRadius="full" size="md">
              Fale conosco
            </Button>
          </Link>
        </Flex>
      </S.MainBox>
    </Stack>
  )
}

export default Layout404
