import { useRef, useState } from 'react'

import { useFormikContext, FormikValues } from 'formik'

import { useToast } from '@chakra-ui/react'

import { IFileUploadControllerProps } from './types'

export const useControllerFileUpload = ({
  name,
  isMultiple,
  maxFileSize = 1,
}: IFileUploadControllerProps) => {
  const toast = useToast()

  const { values, setValues } = useFormikContext<FormikValues>()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      addNewFiles(files)

      event.target.value = ''
    }
  }

  const handleRemoveFile = (file: File) => {
    if (isMultiple) {
      const files = values[name] as Array<File>
      const filteredFiles = files.filter(f => f.name !== file.name)

      setValues({
        ...values,
        [name]: filteredFiles.length > 0 ? filteredFiles : null,
      })
    } else {
      setValues({
        ...values,
        [name]: null,
      })
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()

    const files = event.dataTransfer.files

    if (files) {
      addNewFiles(files)
    }
  }

  const addNewFiles = (files: FileList) => {
    const filesArray = Array.from(files)
    const filesSize = filesArray.reduce((acc, file) => acc + file.size, 0)

    if (filesSize > maxFileSize * Math.pow(10, 6)) {
      toast({
        title: 'Erro ao adicionar arquivo',
        description: `O arquivo deve ter até ${maxFileSize}MB`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else {
      if (isMultiple) {
        setValues({
          ...values,
          [name]: values[name] ? [...values[name], ...filesArray] : filesArray,
        })
      } else {
        setValues({
          ...values,
          [name]: filesArray[0],
        })
      }
    }
  }

  return {
    values,
    fileInputRef,
    openFileDialog,
    handleAddFiles,
    handleRemoveFile,
    handleDragOver,
    handleDrop,
  }
}
