import React from 'react'

import { Select as ChakraSelect } from 'chakra-react-select'

import { DropdownIndicator } from './parts'
import reactSelectStyles from './styles'
import { ISelectProps } from './types'

const Select = ({ ...rest }: ISelectProps) => {
  return (
    <ChakraSelect
      {...rest}
      chakraStyles={reactSelectStyles()}
      components={{ DropdownIndicator }}
    />
  )
}

export default Select
