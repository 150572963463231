import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  getPermissionsGroups,
  getPermissionsGroup,
  getPermissions,
} from './services'
import {
  TGetPermissionsGroupsResponse,
  TGetPermissionsGroupsParams,
  TGetPermissionsGroupResponse,
  TGetPermissionsGroupParams,
  TGetPermissionsResponse,
} from './types'

export const useQueryPermissionsGroups = (
  input: TGetPermissionsGroupsParams,
  options?: UseQueryOptions<TGetPermissionsGroupsResponse>
) => {
  const queryFunction = () => getPermissionsGroups(input)

  return useQuery<TGetPermissionsGroupsResponse>(
    useQueryPermissionsGroups.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryPermissionsGroups.getFullQueryKey = (
  input: TGetPermissionsGroupsParams
): QueryKey => ['permissions', input]

useQueryPermissionsGroups.queryKey = (
  input: TGetPermissionsGroupsParams
): QueryKey => ['permissions', input]

export const useQueryPermissionsGroup = (
  input: TGetPermissionsGroupParams,
  options?: UseQueryOptions<TGetPermissionsGroupResponse>
) => {
  const queryFunction = () => getPermissionsGroup(input)

  return useQuery<TGetPermissionsGroupResponse>(
    useQueryPermissionsGroup.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryPermissionsGroup.getFullQueryKey = (
  input: TGetPermissionsGroupParams
): QueryKey => ['permission', input]

useQueryPermissionsGroup.queryKey = (input: TGetPermissionsGroupParams) => [
  'permission',
  input,
]

export const useQueryPermissions = (
  options?: UseQueryOptions<TGetPermissionsResponse>
) => {
  const queryFunction = () => getPermissions()

  return useQuery<TGetPermissionsResponse>(
    useQueryPermissions.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryPermissions.getFullQueryKey = (): QueryKey => ['permissions']

useQueryPermissions.queryKey = (): QueryKey => ['permissions']
