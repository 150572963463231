import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useColorModeValue } from '@chakra-ui/react'
import { Flex, Stack, Hide, Image, Text, Button } from '@chakra-ui/react'

import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/logo-sare-b-nd-m-BB.svg'

const Main = () => {
  const navigate = useNavigate()
  const logo = useColorModeValue(ColorfulLogoSARE, WhiteLogoSARE)

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="5"
      borderRadius="md"
      boxShadow="md"
      gap="8"
    >
      <Hide below="sm">
        <Image height="100px" src={logo} alt="Logo Sare" />
      </Hide>
      <Stack maxWidth="525px" align="center" spacing="8">
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Seja bem-vindo(a) ao Sistema
          <br />
          de Análise de Recursos Educacionais
        </Text>
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          lineHeight="150%"
        >
          Estamos chegando perto de concluir tudo, mas ainda estamos finalizando
          a <br /> configuração do seu ambiente de trabalho. Assim que tudo
          estiver pronto, você
          <br /> receberá uma notificação por e-mail.
          <br />
          <br />
          Enquanto isso, fique à vontade para completar seu perfil, atualizando
          seus
          <br /> dados.
        </Text>
        <Button
          size="sm"
          color="white"
          bg="brand.primary.dark_1"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          _active={{
            bg: 'brand.primary.base',
          }}
          width="full"
          onClick={() => navigate('/perfil')}
          borderRadius={250}
        >
          Perfil
        </Button>
      </Stack>
    </Flex>
  )
}

export default Main
