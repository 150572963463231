import React, { useContext, createContext, useState } from 'react'

const SidebarContext = createContext({
  isMini: false,
  toggleSidebarState: () => {},
})

export const SidebarContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isMini, setIsOpen] = useState(false)

  const toggleSidebarState = () => {
    setIsOpen(!isMini)
  }

  return (
    <SidebarContext.Provider
      value={{ isMini: isMini, toggleSidebarState: toggleSidebarState }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => {
  return useContext(SidebarContext)
}
