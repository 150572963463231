import React from 'react'
import {
  FaChevronLeft,
  FaChevronCircleRight,
  FaCheckCircle,
} from 'react-icons/fa'

import { Formik } from 'formik'

import { Stack, Text, Flex, Button, SimpleGrid, Box } from '@chakra-ui/react'

import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import { Input } from '@/components/Forms'
import Select from '@/components/Select'

const Title = () => {
  return (
    <Stack gap={0} color="brand.neutral.dark_2">
      <Text fontSize="xs" fontWeight="bold">
        AL LE 000 000 0000 PP00 00 00 000 001
      </Text>
      <Text fontSize="xs">Versão</Text>
    </Stack>
  )
}

const VolumesTable = () => {
  const tableHeaders: Array<IHeader> = [
    {
      name: 'Volume',
      key: 'volume',
      type: 'element',
      width: '50%',
    },
    {
      name: 'Analista 1',
      key: 'analyst1',
      type: 'element',
      width: '25%',
    },
    {
      name: 'Analista 2',
      key: 'analyst2',
      type: 'element',
      width: '25%',
    },
  ]

  const data = [
    {
      id: 1,
      volume: <Title />,
      analyst1: <Select placeholder="Analista 1" />,
      analyst2: <Select placeholder="Analista 2" />,
    },
    {
      id: 2,
      volume: <Title />,
      analyst1: <Select placeholder="Analista 1" />,
      analyst2: <Select placeholder="Analista 2" />,
    },
    {
      id: 3,
      volume: <Title />,
      analyst1: <Select placeholder="Analista 1" />,
      analyst2: <Select placeholder="Analista 2" />,
    },
    {
      id: 4,
      volume: <Title />,
      analyst1: <Select placeholder="Analista 1" />,
      analyst2: <Select placeholder="Analista 2" />,
    },
  ]

  return <DataTable headers={tableHeaders} data={data} />
}

export default VolumesTable
