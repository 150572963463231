import React from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  Image,
  Hide,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import ColorfulLogoSARE from '@/assets/img/sare/logo/logo-sare-a-d-nm-RL.svg'
import WhiteLogoSARE from '@/assets/img/sare/logo/logo-sare-b-nd-m-BB.svg'
import S from '@/common/Templates/TemplateLogin/styles'

const FirstAccess: React.FC = () => {
  const navigate = useNavigate()
  const { colorMode } = useColorMode()

  const onClickContinue = () => {
    navigate('/')
  }

  return (
    <S.MainBox maxWidth="596px" bg={useColorModeValue('white', 'transparent')}>
      <Hide below="sm">
        <Box display="flex" justifyContent="center">
          <Image
            height="75px"
            src={useColorModeValue(ColorfulLogoSARE, WhiteLogoSARE)}
            alt="Logo Sare"
          />
        </Box>
      </Hide>
      <Box>
        <S.Title
          color={useColorModeValue('green.600', 'white')}
          marginBottom={4}
        >
          Bem-vindo(a)!
        </S.Title>
        <S.Subtitle color={useColorModeValue('brand.primary.dark_1', 'white')}>
          Ficamos felizes em informar que seu acesso foi liberado com sucesso!
          Nossa equipe revisou suas credenciais e agora você pode desfrutar de
          todas as funcionalidades do sistema.
        </S.Subtitle>
      </Box>
      <Box display="flex" justifyContent="center">
        <S.MainButton
          variant={useColorModeValue('solid', 'dark')}
          border={useColorModeValue('none', '1px solid #FFFF00')}
          color={useColorModeValue('white', '#FFFF00')}
          bg={useColorModeValue('brand.primary.dark_1', 'transparent')}
          _hover={useColorModeValue(
            { bg: 'brand.primary.dark_2', color: 'white' },
            { bg: '#FFFF0099', color: 'white' }
          )}
          onClick={onClickContinue}
        >
          Entrar
        </S.MainButton>
      </Box>
    </S.MainBox>
  )
}

export default FirstAccess
