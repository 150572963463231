import React from 'react'

import { Field, ErrorMessage, FormikValues, useFormikContext } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input as ChakraInput,
  Select as ChakraSelect,
  Skeleton,
} from '@chakra-ui/react'

import { ISelectProps } from './types'

export const Select = ({
  name,
  label,
  options,
  placeholder,
  helperText,
  inputProps,
  onChange,
  isLoading,
  ...rest
}: ISelectProps) => {
  const { values, setValues } = useFormikContext<FormikValues>()

  return (
    <FormControl {...rest}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Skeleton isLoaded={!isLoading}>
        <Field
          as={ChakraSelect}
          variant="filled"
          bg="brand.neutral.light_2"
          fontSize="sm"
          name={name}
          placeholder={placeholder}
          _focusVisible={{ bg: 'gray.100' }}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setValues({ ...values, [name]: e.target.value })
            onChange?.(e.target.value)
          }}
          {...inputProps}
        >
          {options?.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Field>
      </Skeleton>
      <FormHelperText>{helperText}</FormHelperText>
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
