import {
  styled,
  ModalHeader as ChakraModalHeader,
  ModalBody as ChakraModalBody,
  Flex,
} from '@chakra-ui/react'

const ModalHeader = styled(Flex, {
  baseStyle: {
    justifyContent: 'space-between',
    px: 6,
    pt: 6,
    color: 'brand.neutral.dark_2',
    fontSize: 'xl',
    fontWeight: 'semibold',
    lineHeight: '150%',
  },
})

const ModalBody = styled(ChakraModalBody, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingY: 6,
    gap: 4,
  },
})

export default {
  ModalHeader,
  ModalBody,
}
