import React, { useEffect, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'

import {
  Box,
  Button,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'

import RegisterFailModal from '../RegisterFailModal'
import { failsList, filtersFailsGroup } from './Data'

const FailsList = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 5,
    itemsCount: 0,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [5, 10, 20, 30, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  const {
    isOpen: isRegisterFailOpen,
    onOpen: onRegisterFailOpen,
    onClose: onRegisterFailClose,
  } = useDisclosure()

  const handleOpenRegisterFailModal = () => {
    onRegisterFailOpen()
  }

  useEffect(() => {
    const itemsCount = failsList.length
    const pageCount = Math.ceil(itemsCount / pagination.pageSize)

    setPagination(prev => ({
      ...prev,
      itemsCount,
      pageCount,
    }))
  }, [failsList, pagination.pageSize])

  return (
    <Stack gap={8}>
      <RegisterFailModal
        isOpen={isRegisterFailOpen}
        onClose={onRegisterFailClose}
        onConfirm={() => {
          onRegisterFailClose()
        }}
      />
      <DataFilter
        canDownload={false}
        canPrint={false}
        onlySearch
        filters={filtersFailsGroup}
        onChangeFilter={() => {}}
      />
      {failsList.map(item => (
        <>
          <HStack gap={8} color="brand.neutral.dark_2">
            <Stack flex={1}>
              <Text fontWeight="bold">{item.title}</Text>
              <Text fontWeight="normal" fontSize="sm">
                {item.description}
              </Text>
            </Stack>
            <HStack flex={1} gap={8}>
              <Stack flex={1}>
                <Text fontWeight="semibold">Código da falha</Text>
                <Box
                  rounded="md"
                  p={5}
                  width="full"
                  height={12}
                  display="flex"
                  alignItems="center"
                  bg={useColorModeValue(
                    'brand.neutral.light_2',
                    'blackAlpha.900'
                  )}
                >
                  <Text fontWeight="normal" fontSize="sm">
                    {item.code}
                  </Text>
                </Box>
              </Stack>
            </HStack>
          </HStack>
        </>
      ))}
      <Button
        size="sm"
        leftIcon={<FaPlusCircle />}
        fontSize="sm"
        color="white"
        bg="brand.primary.dark_1"
        _hover={{
          bg: 'brand.primary.dark_2',
        }}
        // isLoading={}
        // isDisabled={}
        onClick={() => handleOpenRegisterFailModal()}
      >
        Adicionar falha não cadastrada
      </Button>
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Stack>
  )
}

export default FailsList
