import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { postAuthGovBr } from './services'
import { TPostAuthGovBrParams, TPostAuthGovBrResponse } from './types'

export const useMutationPostAuthGovBr = (
  options?: UseMutationOptions<
    TPostAuthGovBrResponse,
    AxiosError<IErrorDTO>,
    TPostAuthGovBrParams
  >
) => {
  return useMutation(postAuthGovBr, options)
}
