import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
  useSearchParams,
} from 'react-router-dom'

import Layout404 from '@/common/Layouts/Layout404'
import TemplateLogin from '@/common/Templates/TemplateLogin'
import TemplatePage from '@/common/Templates/TemplatePage'
import { SidebarContextProvider } from '@/contexts/SidebarContext'
import Checklists from '@/pages/Checklists'
import Checklist from '@/pages/Checklists/Checklist'
import FailList from '@/pages/Checklists/Fail'
import Questions from '@/pages/Checklists/Questions'
import Login from '@/pages/Login'
import LoginDev from '@/pages/Login/Dev'
import LoginFirstAccess from '@/pages/Login/FirstAccess'
import LoginAccessDenied from '@/pages/Login/LoginAccessDenied'
import Objects from '@/pages/Objects'
import DetailObjects from '@/pages/Objects/DetailObjects'
import ImportObjects from '@/pages/Objects/ImportObjects'
import ObjectsTeamDistribution from '@/pages/Objects/TeamDistribution'
import ObjectsTeamRedistribution from '@/pages/Objects/TeamRedistribution'
import ObjectsTeamRedistributionByMember from '@/pages/Objects/TeamRedistribution/ByMember'
import ObjectsTeamRedistributionByVolume from '@/pages/Objects/TeamRedistribution/ByVolume'
import ObjectsTeamRedistributionChoice from '@/pages/Objects/TeamRedistribution/Choice'
import ObjectsVolumeSizing from '@/pages/Objects/VolumeSizing'
import Panel from '@/pages/Panel'
import PanelMain from '@/pages/Panel/Main'
import PanelNotifications from '@/pages/Panel/Notifications'
import PanelObjects from '@/pages/Panel/Objects'
import Profile from '@/pages/Profile'
import PersonalInformation from '@/pages/Profile/PersonalInformation'
import PersonalLink from '@/pages/Profile/PersonalLink'
import SystemSettings from '@/pages/SystemSettings'
import SystemSettingsPermissions from '@/pages/SystemSettings/Permissions'
import SystemSettingsPermissionsDetails from '@/pages/SystemSettings/Permissions/Details'
import SystemSettingsTeam from '@/pages/SystemSettings/Teams'
import SystemSettingsTeamDetails from '@/pages/SystemSettings/Teams/Details'
import SystemSettingsUser from '@/pages/SystemSettings/User'
import SystemSettingsDetailsUser from '@/pages/SystemSettings/User/Details'

import RegisterFailsGroupChecklist from './pages/Checklists/RegisterFailsGroup'

const RedirectGovBrLogin = () => {
  const [searchParams] = useSearchParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  if (code && state) {
    return <Navigate to={`/login/?code=${code}&state=${state}`} replace />
  }

  return <Outlet />
}

class App extends React.Component {
  render() {
    const isDevEnvironment = process.env.REACT_APP_ENVIRONMENT === 'dev'

    return (
      <SidebarContextProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<RedirectGovBrLogin />}>
              <Route path="/" element={<TemplatePage />}>
                <Route path="painel" element={<Panel />}>
                  <Route path="principal" element={<PanelMain />} />
                  <Route path="notificacoes" element={<PanelNotifications />} />
                  <Route path="objetos" element={<PanelObjects />} />
                  <Route index element={<Navigate to="principal" />} />
                </Route>
                <Route path="perfil" element={<Profile />}>
                  <Route
                    path="informacoes-pessoais"
                    element={<PersonalInformation />}
                  />
                  <Route path="vinculos" element={<PersonalLink />} />
                  <Route
                    index
                    element={<Navigate to="informacoes-pessoais" />}
                  />
                </Route>

                <Route path="configuracoes" element={<SystemSettings />}>
                  <Route path="usuario">
                    <Route index element={<SystemSettingsUser />} />
                    <Route
                      path="detalhes/:userId"
                      element={<SystemSettingsDetailsUser />}
                    />
                  </Route>
                  <Route path="equipe">
                    <Route index element={<SystemSettingsTeam />} />
                    <Route
                      path="detalhes/:teamId"
                      element={<SystemSettingsTeamDetails />}
                    />
                  </Route>
                  <Route path="permissoes">
                    <Route index element={<SystemSettingsPermissions />} />
                    <Route
                      path="detalhes/:permissionId"
                      element={<SystemSettingsPermissionsDetails />}
                    />
                  </Route>
                  <Route index element={<Navigate to="usuario" />} />
                </Route>
                <Route path="objetos">
                  <Route index element={<Objects />} />
                  <Route
                    path="detalhes/:coObject"
                    element={<DetailObjects />}
                  />
                  <Route path="importar" element={<ImportObjects />} />
                  <Route
                    path="dimensionamento-volumes"
                    element={<ObjectsVolumeSizing />}
                  />
                  <Route
                    path="distribuicao-times"
                    element={<ObjectsTeamDistribution />}
                  />
                  <Route
                    path="redistribuicao-times"
                    element={<ObjectsTeamRedistribution />}
                  >
                    <Route
                      index
                      element={<ObjectsTeamRedistributionChoice />}
                    />
                    <Route
                      path="por-membro"
                      element={<ObjectsTeamRedistributionByMember />}
                    />
                    <Route
                      path="por-volume"
                      element={<ObjectsTeamRedistributionByVolume />}
                    />
                  </Route>
                </Route>
                <Route path="formularios" element={<Checklists />}>
                  <Route path="formulario" element={<Checklist />} />
                  <Route path="diligencias" element="#" />
                  <Route path="perguntas" element={<Questions />} />
                  <Route path="falhas" element={<FailList />} />
                  <Route
                    path="cadastrar-grupo-de-falhas"
                    element={<RegisterFailsGroupChecklist />}
                  />
                  <Route index element={<Navigate to="formulario" />} />
                </Route>
                <Route index element={<Navigate to="painel" />} />
              </Route>
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="login" element={<TemplateLogin />}>
              {isDevEnvironment ? (
                <Route path="desenvolvedor" element={<LoginDev />} />
              ) : null}
              <Route path="primeiro-acesso" element={<LoginFirstAccess />} />
              <Route path="acesso-negado" element={<LoginAccessDenied />} />
            </Route>
            <Route path="*" element={<Layout404 />} />
          </Routes>
        </BrowserRouter>
      </SidebarContextProvider>
    )
  }
}

export default App
