import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle = definePartsStyle({
  tab: {
    color: mode('gray.700', 'gray.50'),
    _selected: {
      color: 'brand.primary.base',
      marginBottom: '-2px',
      borderBottom: '2px',
    },
  },
})

const roundedVariant = definePartsStyle({
  tab: {
    fontSize: '16px',
    fontWeight: 'semibold',
    color: 'brand.neutral.dark_1',
    _selected: {
      marginBottom: 'unset',
      borderBottom: 'unset',
      bg: 'brand.primary.light_2',
      color: 'brand.primary.dark_1',
    },
    borderRadius: 'full',
    py: 1,
  },
  tablist: {
    borderBottom: 'none',
  },
})

const variants = {
  rounded: roundedVariant,
}

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
})
