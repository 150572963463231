import React from 'react'

import { CircularProgress, Stack, Text, useToast } from '@chakra-ui/react'

import { useQueryGetObjects } from '@/api/objects/queries'
import EmptyObjects from '@/pages/Objects/EmptyObjects'
import ListObjects from '@/pages/Objects/ListObjects'
import { getErrorDetails } from '@/utils/error'

const Objects = () => {
  const toast = useToast()

  const {
    data: objects,
    isLoading,
    error,
  } = useQueryGetObjects({
    onError: error => {
      toast({
        title: 'Erro recebendo os dados, contate o suporte.',
        description: getErrorDetails(error),
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  if (isLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        minHeight="85vh"
        backgroundColor="white"
      >
        <Text p="1">Buscando dados dos objetos...</Text>
        <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
      </Stack>
    )
  }

  if (!isLoading && (!objects || objects.length === 0)) {
    return <EmptyObjects />
  }

  return <ListObjects objects={objects} />
}

export default Objects
