import React from 'react'
import { FaBan } from 'react-icons/fa6'

import { Flex, Icon, Stack, Text } from '@chakra-ui/react'

const Objects = () => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="5"
      borderRadius="md"
      boxShadow="md"
      gap="8"
    >
      <Icon
        as={FaBan}
        boxSize="128px"
        color="brand.primary.dark_1"
        rotate="30deg"
      />
      <Stack maxWidth="400px" align="center" spacing="4">
        <Text
          color="brand.primary.dark_2"
          textAlign="center"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Você ainda não tem objetos vinculados!
        </Text>
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight="400"
          lineHeight="150%"
        >
          Estamos no processo de distribuição dos objetos. Assim que você for
          alocado a uma equipe e seus itens estiverem prontos, você receberá uma
          notificação em seu e-mail.
          <br />
          <br />
          Agradecemos pela sua paciência!
        </Text>
      </Stack>
    </Flex>
  )
}

export default Objects
