import React from 'react'
import { FaCopy, FaEdit, FaGripVertical } from 'react-icons/fa'

import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Button,
  Divider,
} from '@chakra-ui/react'

import StatusTag from '@/components/Tag/StatusCardTeam'

import { ICardComponentProps } from './types'

const CardComponent: React.FC<ICardComponentProps> = ({
  id,
  title,
  description,
  status,
  children,
  dragHandleProps,
  onCopy = () => {},
  onEdit = () => {},
}) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700')
  const backgroundColor = useColorModeValue('white', 'gray.800')

  return (
    <Flex
      direction="column"
      alignItems="center"
      alignSelf="stretch"
      padding={4}
      borderRadius="lg"
      borderWidth="1px 1px 3px 1px"
      my={2}
      borderColor={borderColor}
      bg={backgroundColor}
      boxShadow="sm"
    >
      <Flex direction="row" alignItems="center" gap={4} alignSelf="stretch">
        <IconButton
          aria-label="Grip"
          icon={<FaGripVertical />}
          color="brand.primary.dark_1"
          padding={3}
          borderRadius="3xl"
          variant="ghost"
          _hover={{ backgroundColor: 'gray.100' }}
          {...dragHandleProps}
        />

        <Flex flex="1" direction="row" alignItems="center" gap={4}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="flex-start"
            flex="1"
          >
            {title && (
              <Text
                alignSelf="stretch"
                color="brand.neutral.dark_2"
                fontSize="md"
                fontWeight="bold"
                lineHeight="140%"
              >
                {title}
              </Text>
            )}
            {description && (
              <Text
                color="brand.neutral.dark_2"
                fontSize="sm"
                fontWeight="normal"
                lineHeight="150%"
              >
                {description}
              </Text>
            )}
          </Box>

          <Divider
            orientation="vertical"
            height={8}
            bg="brand.neutral.light_1"
          />

          <StatusTag tag={status.tag} />

          <Button
            paddingY={3}
            paddingX={6}
            borderRadius="3xl"
            border="2px solid"
            borderColor="brand.neutral.dark_1"
            backgroundColor="transparent"
            _hover={{ backgroundColor: 'gray.50' }}
            leftIcon={<FaEdit />}
            color="brand.primary.dark_1"
            onClick={() => onEdit(id)}
          >
            Editar
          </Button>

          <IconButton
            aria-label="Copiar"
            icon={<FaCopy />}
            variant="ghost"
            borderRadius="3xl"
            colorScheme="blue"
            color="brand.primary.dark_1"
            onClick={() => onCopy(id)}
          />
        </Flex>
      </Flex>

      {children}
    </Flex>
  )
}

export default CardComponent
