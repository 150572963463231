import React, { useState } from 'react'
import { FaChevronLeft, FaCheckCircle } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

import { Flex, Box, Button, useColorMode, useToast } from '@chakra-ui/react'

import { useQueryUser } from '@/api/user/management/queries'
import Profile from '@/assets/img/sare/sidebar/profile.png'
import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'
import Tag from '@/components/Tag'
import BondsForm from '@/pages/SystemSettings/User/Details/BondsForm'
import UserInfoForm from '@/pages/SystemSettings/User/Details/UserInfoForm'

import { IUserStatusMapping } from '../types'
import Permissions from './Permissions'
import S from './styles'
import Teams from './Teams'

const Details = () => {
  const { userId } = useParams()
  const toast = useToast()

  const [avatarSrc, setAvatarSrc] = useState(Profile)

  const [activeTabId, setActiveTabId] = useState('informacoes-pessoais')

  const {
    data: userData,
    isLoading,
    isError,
  } = useQueryUser(
    { coUser: userId as string },
    {
      enabled: !!userId,
      onError: () => {
        toast({
          title: 'Erro ao buscar dados do usuário',
          description: 'Não foi possível obter os dados do usuário.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      },
    }
  )

  const isAdmin = userData?.ncoProfile[0] === 1
  const statusTagValue = IUserStatusMapping[userData?.dsStatus as string]

  const tabs: Array<ITabItem> = [
    {
      id: 'informacoes-pessoais',
      label: 'Informações pessoais',
      render: () => {
        return userId && <UserInfoForm userId={userId} />
      },
    },
    {
      id: 'permissoes',
      label: 'Permissões',
      render: () => <Permissions />,
    },
    {
      id: 'vinculos',
      label: 'Vínculos',
      render: () => {
        return userId && <BondsForm userId={userId} />
      },
    },
    {
      id: 'equipes',
      label: 'Equipes',
      render: () => <Teams />,
    },
  ]

  const handleAvatarChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setAvatarSrc(reader.result)
        }
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <Flex direction="column">
      <Flex
        direction="column"
        padding="5"
        gap="12"
        borderRadius="0.3125rem"
        boxShadow="0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
      >
        <S.TopContainer>
          <S.AvatarContainer>
            <S.StyledAvatar name="Nome de usuário" src={avatarSrc} />
          </S.AvatarContainer>
          <S.UserInfo>
            <S.UserNameText color="brand.primary.dark_1">
              {userData?.dsLogin}
            </S.UserNameText>
            <S.UserEmailText color="gray.700">
              {userData?.dsGovbrEmail}
            </S.UserEmailText>
            {!isAdmin && (
              <Tag group="statusUserSystem" value={statusTagValue} />
            )}
          </S.UserInfo>
        </S.TopContainer>
        <Box>
          <Tabs
            items={tabs}
            initialTab={activeTabId}
            onTabClick={({ id }: ITabInfo) => setActiveTabId(id)}
          />
          <Flex
            direction="row"
            justifyContent="flex-end"
            gap="2"
            marginTop="10"
          >
            <Button
              size="sm"
              leftIcon={<FaChevronLeft />}
              variant="ghost"
              fontSize="sm"
              color="brand.primary.dark_1"
            >
              Voltar
            </Button>
            <Button
              size="sm"
              leftIcon={<FaCheckCircle />}
              fontSize="sm"
              color="white"
              bg="brand.primary.dark_1"
              _hover={{
                bg: 'brand.primary.dark_2',
              }}
            >
              Salvar alterações
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Details
