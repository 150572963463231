import React, { useState } from 'react'

import { EditIcon } from '@chakra-ui/icons'
import { Flex, IconButton } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

const Teams = () => {
  const editButton = (id: number) => {
    return (
      <IconButton
        aria-label="Editar"
        variant="ghost"
        size="sm"
        height="unset"
        icon={<EditIcon />}
        color="brand.primary.dark_1"
      />
    )
  }
  const header: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'team',
    },
    {
      name: 'OBJETO',
      key: 'object',
    },
    {
      name: 'FASE',
      key: 'phase',
    },
    {
      name: 'PERMISSÃO',
      key: 'permission',
    },
    {
      name: 'STATUS',
      key: 'status',
      type: 'element',
      align: 'center',
      width: '10%',
    },
    {
      name: '',
      key: 'edit',
      type: 'element',
      width: '5%',
    },
  ]

  const tableData = [
    {
      team: 'EQ123',
      object: 'Obj0123456',
      phase: 'fase',
      permission: 'Analista 1',
      status: <Tag group="statusUserTeam" value="active" />,
      edit: editButton(1),
    },
    {
      team: 'EQ123',
      object: 'Obj0123456',
      phase: 'fase',
      permission: 'Analista 1',
      status: <Tag group="statusUserTeam" value="finished" />,
      edit: editButton(2),
    },
    {
      team: 'EQ123',
      object: 'Obj0123456',
      phase: 'fase',
      permission: 'Analista 1',
      status: <Tag group="statusUserTeam" value="on_hold" />,
      edit: editButton(3),
    },
  ]

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    // TODO: call api to get data paginated
  }

  return (
    <Flex direction="column" gap={4}>
      <Flex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={4}
      >
        <DataFilter
          testId="data-filter"
          filters={[]}
          onlySearch
          canDownload={false}
          canPrint={false}
          onChangeFilter={() => {}}
        />
      </Flex>
      <DataTable headers={header} data={tableData} />
      <Pagination
        optionsItemsPerPage={[10, 20, 30, 40, 50]}
        itemsPerPage={10}
        pageCount={10}
        itemsCount={100}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Flex>
  )
}

export default Teams
