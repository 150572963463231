import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusUserTeamTagProps, IStatusUserTeamTagStyles } from './types'

const styles: IStatusUserTeamTagStyles = {
  active: {
    name: 'Ativo',
    backgroundColor: 'blue.600',
  },
  finished: {
    name: 'Finalizado',
    backgroundColor: 'green.600',
  },
  on_hold: {
    name: 'Em espera',
    backgroundColor: 'gray.600',
  },
  default: {
    name: 'default',
    backgroundColor: 'gray.500',
  },
}

function StatusTag({ tag, ...rest }: IStatusUserTeamTagProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
