import React, { useMemo } from 'react'
import { useState } from 'react'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'

import { Button, Text, Flex, useToast } from '@chakra-ui/react'
import { RowData } from '@tanstack/react-table'

import { useMutationDeleteTeams } from '@/api/teams/mutations'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

import { IDeleteModalProps, ITeam } from './types'

const DeleteTeamModal = ({
  isOpen,
  onClose,
  teams,
}: IDeleteModalProps): JSX.Element => {
  const toast = useToast()
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedTeams, setSelectedTeams] = useState<Array<RowData>>([])

  const pageInfo = useMemo(() => {
    const itemsPerPage = 3

    return {
      itemsPerPage,
      pageCount: Math.ceil(teams.length / itemsPerPage),
      itemsCount: teams.length,
    }
  }, [teams])

  const teamsPaginated = useMemo(() => {
    const start = page * pageInfo.itemsPerPage
    const end = start + pageInfo.itemsPerPage

    return teams.slice(start, end)
  }, [page, teams])

  const { mutate: deleteTeam } = useMutationDeleteTeams({
    onSuccess: () => {
      toast({
        title: 'Equipe desativada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
      })
      onClose()
    },
    onError: () => {
      toast({
        title: 'Erro ao desativar equipe',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
  })

  const handleDelete = () => {
    const selectedTeams = teams.map(team => (team as { coTeam: number }).coTeam)

    const payload = {
      coTeam: selectedTeams,
    }
    deleteTeam(payload)
  }

  const mainTableHeaders: Array<IHeader> = [
    {
      name: 'EQUIPE',
      key: 'user',
      type: 'element',
    },
    {
      name: 'SUPERVISOR',
      key: 'supervisor',
      type: 'element',
    },
    {
      name: 'DATA DE CRIAÇÃO',
      key: 'date',
    },
  ]

  return (
    <Modal
      title="Excluir equipes"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Text height="63px" mb="1rem">
        Confira as equipes que você selecionou antes de confirmar a exclusão.
        Deseja desativar as equipes abaixo?
      </Text>
      <Flex direction="column">
        <DataTable
          headers={mainTableHeaders}
          data={teamsPaginated}
          isLoading={isLoading}
          selectableRow
          onRowSelectionChange={rows => setSelectedTeams(rows)}
        />
        <Pagination
          itemsPerPage={pageInfo.itemsPerPage}
          pageCount={pageInfo.pageCount}
          itemsCount={pageInfo.itemsCount}
          onChangePageInfo={({ pageIndex }) => setPage(pageIndex)}
        />
      </Flex>
      <Flex gap={8} justifyContent={'flex-end'}>
        <Button
          leftIcon={<FaTimesCircle size="14px" color="white" />}
          size="sm"
          backgroundColor="brand.error.base"
          color="white"
          _hover={{ bg: 'brand.error.dark' }}
          onClick={onClose}
        >
          Não, mantenha ativo
        </Button>
        <Button
          leftIcon={<FaCheckCircle size="14px" color="white" />}
          size="sm"
          backgroundColor="brand.primary.dark_1"
          color="white"
          _hover={{ bg: 'brand.primary.dark_2' }}
          onClick={handleDelete}
        >
          Sim, quero desativar
        </Button>
      </Flex>
    </Modal>
  )
}

export default DeleteTeamModal
