import React from 'react'
import { NavLink as ReactRouterLink } from 'react-router-dom'

import { Link as ChakraLink } from '@chakra-ui/react'

import S from '../../styles'
import { IHeaderNavItemProps } from '../../types'

const HeaderNavItem = ({
  onClose,
  to,
  children,
  ...rest
}: IHeaderNavItemProps) => {
  return (
    <ChakraLink
      as={ReactRouterLink}
      to={to}
      onClick={onClose}
      color="brand.highlight.light"
      _activeLink={{
        bg: 'brand.highlight.base',
        color: 'white',
      }}
    >
      <S.HeaderItem
        variant="ghost"
        _hover={{
          color: 'brand.highlight.dark',
          bg: 'brand.neutral.light_2',
        }}
        {...rest}
      >
        {children}
      </S.HeaderItem>
    </ChakraLink>
  )
}

export default HeaderNavItem
