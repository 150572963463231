import http from '@/api/http'

import { TPostAuthLocalLoginParams, TPostAuthLocalLoginResponse } from './types'

export const postAuthLocalLogin = async (params: TPostAuthLocalLoginParams) => {
  const response = await http.post<TPostAuthLocalLoginResponse>(
    '/auth/local/login/',
    params
  )

  return response.data
}
