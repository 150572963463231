import React from 'react'
import { useMemo, useState } from 'react'
import { FaBook, FaCircleExclamation } from 'react-icons/fa6'

import { Box, Button, Flex, Icon, Text, VStack } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

import { statusFilter, tableHeaders } from './controller'

interface ITeamsProps {
  // TODO: Modelar dados para a API
  teams: Array<any>
}

const Phase1Teams: React.FC<ITeamsProps> = ({ teams }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: teams.length,
    pageCount: Math.ceil(teams.length / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(teams.length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = teams.slice(start, end)

    return paginatedData.map(item => {
      return {
        ...item,
        status: <Tag group="statusObjects" value={item.status} />,
      }
    })
  }, [teams, pagination])

  if (!teams.length) {
    return (
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        gap={6}
        padding="60px"
      >
        <Icon as={FaCircleExclamation} boxSize="128px" color="#475671" />
        <Box w="50%">
          <Text
            color="brand.primary.dark_2"
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Esse objeto ainda não teve seus volumes dimensionados.
          </Text>
        </Box>
      </Flex>
    )
  }

  return (
    <Flex w="full" direction="column" gap={8}>
      <Box>
        <DataFilter
          testId="data-filter"
          canDownload={false}
          canPrint={false}
          onlySearch
          filters={[statusFilter]}
          onChangeFilter={() => {}}
        />
      </Box>
      <Box>
        <DataTable
          key="table-teams-modal"
          data={mapTableData}
          headers={tableHeaders}
          selectableRow={false}
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Box>
      <Flex justify="end">
        <Button leftIcon={<Icon as={FaBook} color="#475671" />}>
          <Text fontSize="sm" color="gray.700">
            Dimensionar volumes
          </Text>
        </Button>
      </Flex>
    </Flex>
  )
}

export default Phase1Teams
