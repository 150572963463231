import { IFilter } from '@/components/DataFilter'
import { IHeader } from '@/components/DataTable/types'

export const tableHeaders: Array<IHeader> = [
  {
    name: 'Volume',
    key: 'volumeCode',
    type: 'element',
    width: 'auto',
  },
  {
    name: 'Equipe',
    key: 'team',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'element',
    width: 'auto',
    align: 'center',
  },
]

export const statusFilter: IFilter = {
  name: 'status',
  label: 'Status',
  type: 'select',
  options: [
    { label: 'Ativo', value: 'active' },
    { label: 'Inativo', value: 'inactive' },
    { label: 'Finalizado', value: 'finished' },
    { label: 'Em andamento', value: 'ongoing' },
    { label: 'Aguardando vínculo', value: 'waiting_bond' },
    { label: 'Fase Externa', value: 'external_phase' },
    { label: 'Não iniciado', value: 'not_started' },
  ],
}
