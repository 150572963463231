import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { IoMdSettings } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import { Button, Flex, Icon, Text } from '@chakra-ui/react'

const EmptyObjects = () => {
  const navigate = useNavigate()
  const handleImportData = () => {
    // TODO: Implement import data logic
    navigate('importar')
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      padding="5"
      borderRadius="md"
      boxShadow="md"
      gap="8"
    >
      <Icon as={IoMdSettings} boxSize="128px" color="brand.primary.dark_1" />
      <Text
        color="#0C326F"
        textAlign="center"
        fontSize="20px"
        fontWeight="700"
        lineHeight="120%"
      >
        Você ainda não tem objetos importados!
        <br />
        Deseja importar agora?
      </Text>
      <Button
        leftIcon={<FaCheckCircle />}
        bg="brand.primary.dark_1"
        color="#FFFFFF"
        size="sm"
        borderRadius="md"
        paddingY={3}
        paddingX={6}
        height={10}
        fontWeight="normal"
        onClick={handleImportData}
        _hover={{
          bg: 'brand.primary.dark_2',
        }}
      >
        Sim, importar agora
      </Button>
    </Flex>
  )
}

export default EmptyObjects
