import React from 'react'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { FaChevronLeft } from 'react-icons/fa'

import { Formik, Form, FormikValues, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'

import { TUpdateMyAccountParams } from '@/api/user/management/types'
import FileUpload from '@/components/FileUpload'
import Modal from '@/components/Modal'
import { parseImgToBase64 } from '@/utils/parser'

import { useProfileController } from '../controller'
import { IModalProps } from './types'

export const ModalProfile: React.FC<IModalProps> = ({ isOpen, onClose }) => {
  const { userValues, updateMyAccount, isUpdatingAccount } =
    useProfileController()

  const removePicture = () => {
    const payload = {
      coUser: userValues.coUser,
      dsPhotoUrl: '',
    }

    updateMyAccount(payload as TUpdateMyAccountParams)
  }

  const handleSubmit = async (values: FormikValues) => {
    const payload = {
      coUser: userValues.coUser,
      dsPhotoUrl: await parseImgToBase64(values.file),
    }

    updateMyAccount(payload as TUpdateMyAccountParams)
  }

  return (
    <Modal
      title="Editar foto de perfil"
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
    >
      <Formik
        initialValues={{
          file: '',
        }}
        validationSchema={yup.object().shape({
          file: yup.string().required('Selecione uma imagem'),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Box padding={2}>
            <Text fontSize="sm" color="gray.600" marginBottom={2}>
              Foto do perfil{''}
              <Text as="span" color="red.500">
                *
              </Text>
            </Text>
            <FileUpload
              id="file"
              name="file"
              helperText="Só aceitamos imagens (.png ou .jpg)"
              acceptedFileTypes={['image/*']}
              maxFileSize={10}
            />
            <ErrorMessage name="file">
              {(message: string) => (
                <Text color="red.600" fontSize="sm">
                  {message}
                </Text>
              )}
            </ErrorMessage>
          </Box>
          <Flex
            padding={2}
            justify="flex-end"
            gap={2}
            direction={{ base: 'column', sm: 'row' }}
          >
            <Button
              type="button"
              variant="ghost"
              size="sm"
              leftIcon={<Icon as={FaChevronLeft} />}
              isDisabled={isUpdatingAccount}
              onClick={onClose}
            >
              Voltar
            </Button>
            <Button
              key="go-back"
              size="sm"
              leftIcon={<BsXCircle />}
              variant="error"
              isDisabled={isUpdatingAccount || !userValues.dsPhotoUrl}
              onClick={removePicture}
            >
              Remover foto
            </Button>
            <Button
              key="confirm"
              type="submit"
              size="sm"
              leftIcon={<BsCheckCircle />}
              isLoading={isUpdatingAccount}
            >
              Salvar foto
            </Button>
          </Flex>
        </Form>
      </Formik>
    </Modal>
  )
}
