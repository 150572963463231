import { styled, Button, Text, Link } from '@chakra-ui/react'

const HeaderItem = styled(Button, {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    padding: 3,
    borderRadius: 'none',
    color: 'white',
    height: 12,
  },
})

const NavItem = styled(Link, {
  baseStyle: {
    display: 'flex',
    alignItems: 'center',
    p: 3,
    cursor: 'pointer',
    borderBottom: '1px solid',
    textDecoration: 'none',
  },
})

const NavItemName = styled(Text, {
  baseStyle: {
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
})

export default {
  HeaderItem,
  NavItem,
  NavItemName,
}
