import React from 'react'
import { FaChevronLeft, FaCheckCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import { Formik } from 'formik'

import {
  Stack,
  Text,
  Flex,
  Button,
  SimpleGrid,
  useDisclosure,
} from '@chakra-ui/react'

import Accordion from '@/components/Accordion'
import { Input } from '@/components/Forms'
import Modal from '@/components/Modal'

const TeamForm = ({
  co_team,
  num_analists = 1,
}: {
  co_team: number
  num_analists?: number
}) => {
  return (
    <SimpleGrid columns={2} spacing={4}>
      <Input name={`team_${co_team}-supervisor`} label="Supervisor" />
      {[...Array(num_analists)].map((_, index) => (
        <Input
          key={index}
          name={`team_${co_team}-analist_${index}`}
          label="Analista"
        />
      ))}
    </SimpleGrid>
  )
}

const ByMember = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const accordionItems = [
    {
      title: 'EQ001',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={1} />,
      tag: 'Teste',
    },
    {
      title: 'EQ002',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={2} />,
    },
    {
      title: 'EQ003',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={3} />,
    },
    {
      title: 'EQ004',
      description: '3 coleções | 18 volumes',
      children: <TeamForm co_team={4} />,
    },
  ]

  const handleConfirmSave = () => {
    // TODO: Do something
    onClose()
  }

  return (
    <Stack gap={6} flex={1} justify="space-between">
      <Stack gap={4}>
        <Stack gap={2}>
          <Text fontSize="xl" color="brand.primary.dark_1" fontWeight="bold">
            Por membro
          </Text>
          <Text fontSize="xs">
            Ao trocar o membro da equipe todo as suas atividades em aberto serão
            passadas para o novo membro.
          </Text>
        </Stack>
        <Formik initialValues={{}} onSubmit={() => {}}>
          <Accordion items={accordionItems} />
        </Formik>
      </Stack>
      <Flex justify="space-between">
        <Button
          variant="ghost"
          leftIcon={<FaChevronLeft />}
          onClick={() => navigate('/objetos/redistribuicao-times')}
        >
          Sair sem salvar
        </Button>
        <Button variant="success" leftIcon={<FaCheckCircle />} onClick={onOpen}>
          Salvar nova distribuição
        </Button>
      </Flex>
      <Modal
        title="Deseja finalizar a redistribuição dos equipes para esta fase?"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
      >
        <Flex justify="flex-end" gap={4}>
          <Button variant="outline" borderRadius="full" onClick={onClose}>
            Não, voltar a redistribuição
          </Button>
          <Button borderRadius="full" onClick={handleConfirmSave}>
            Sim, desejo finalizar
          </Button>
        </Flex>
      </Modal>
    </Stack>
  )
}

export default ByMember
