import React from 'react'
import { useMemo, useState } from 'react'

import { Box } from '@chakra-ui/react'

import DataFilter from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import Tag from '@/components/Tag'

import { statusFilter, tableHeaders } from './controller'

interface IOverviewProps {
  // TODO: Modelar dados para a API
  details: Array<any>
}

const Overview: React.FC<IOverviewProps> = ({ details }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: details.length,
    pageCount: Math.ceil(details.length / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(details.length / itemsPerPage),
    })
  }

  const mapTableData = useMemo(() => {
    const start = (pagination.page - 1) * pagination.pageSize
    const end = start + pagination.pageSize
    const paginatedData = details.slice(start, end)

    return paginatedData.map(item => {
      return {
        ...item,
        phase1: <Tag group="statusObjects" value={item.phase1} />,
        phase3: <Tag group="statusObjects" value={item.phase3} />,
        phase4: <Tag group="statusObjects" value={item.phase4} />,
        phase5: <Tag group="statusObjects" value={item.phase5} />,
      }
    })
  }, [details, pagination])

  return (
    <Box w="full">
      <Box py={4}>
        <DataFilter
          testId="data-filter"
          canDownload={false}
          canPrint={false}
          onlySearch
          filters={[statusFilter]}
          onChangeFilter={() => {}}
        />
      </Box>
      <Box>
        <DataTable
          key="table-details-modal"
          data={mapTableData}
          headers={tableHeaders}
          selectableRow={false}
        />
        <Pagination
          pageIndex={pagination.page - 1}
          optionsItemsPerPage={pagination.pageSizeOptions}
          itemsPerPage={pagination.pageSize}
          pageCount={pagination.pageCount}
          itemsCount={pagination.itemsCount}
          onChangePageInfo={handleOnChangePageInfo}
        />
      </Box>
    </Box>
  )
}

export default Overview
