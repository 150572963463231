import React from 'react'
import { FaCircleExclamation } from 'react-icons/fa6'

import { Box, Flex, Icon, Text } from '@chakra-ui/react'

import Tag from '@/components/Tag'

const Phase2: React.FC = () => {
  return (
    <Flex direction="column" gap={6}>
      <Flex direction="column" gap={1}>
        <Text
          color="#475671"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Avaliação Pedagógica
        </Text>
        <Box>
          <Tag group="statusObjects" value="external_phase" />
        </Box>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        flexGrow={1}
        gap={6}
        padding="60px"
      >
        <Icon as={FaCircleExclamation} boxSize="128px" color="#475671" />
        <Box w="50%">
          <Text
            color="brand.primary.dark_2"
            textAlign="center"
            fontSize="20px"
            fontWeight="700"
            lineHeight="120%"
          >
            Essa fase ocorre externamente ao nosso sistema. <br />
            Por favor, entre em contato com seu coordenador para mais
            informações.
          </Text>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Phase2
