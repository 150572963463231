import { AxiosError } from 'axios'

import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getSpecialNeeds, getEducationLevels } from './services'
import { TGetSpecialNeedsResponse } from './types'

export const useQueryGetSpecialNeeds = (
  options?: UseQueryOptions<TGetSpecialNeedsResponse, AxiosError<IErrorDTO>>
) => {
  return useQuery<TGetSpecialNeedsResponse, AxiosError<IErrorDTO>>(
    ['specialNeeds'],
    getSpecialNeeds,
    options
  )
}

export const useQueryGetEducationLevels = (
  options?: UseQueryOptions<TGetSpecialNeedsResponse, AxiosError<IErrorDTO>>
) => {
  return useQuery<TGetSpecialNeedsResponse, AxiosError<IErrorDTO>>(
    ['educationLevels'],
    getEducationLevels,
    options
  )
}
