import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { IFilter } from '@/components/DataFilter'
import Tabs from '@/components/Tabs'
import { ITabInfo, ITabItem } from '@/components/Tabs/types'

import QuestionsList from './Questions'

// TODO: Analisar a questão das tabs
const Questions = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const initialPath = location.pathname.split('/').pop()

  const tabs: Array<ITabItem> = [
    {
      id: 'formulario',
      label: 'Formulário',
    },
    {
      id: 'diligencias',
      label: 'Diligências',
    },
    {
      id: 'perguntas',
      label: 'Perguntas',
    },
    {
      id: 'falhas',
      label: 'Falhas',
    },
  ]

  const handleTabClick = (id: string) => {
    navigate(`/formularios/${id}`)
  }

  const filters: Array<IFilter> = [
    {
      name: 'answer_type',
      label: 'Tipo de resposta',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'form_type',
      label: 'Formato do volume',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
  ]

  return (
    <>
      <Tabs
        items={tabs}
        initialTab={initialPath}
        onTabClick={({ id }: ITabInfo) => handleTabClick(id)}
      />
      <QuestionsList />
    </>
  )
}

export default Questions
