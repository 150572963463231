import React from 'react'

import FailsList from './parts/FailsList'
import { ITabItemFailsGroup } from './types'

export const failsListTabs: Array<ITabItemFailsGroup> = [
  {
    id: 'pdf',
    label: 'PDF',
    render: () => <FailsList />,
  },
  {
    id: 'impression',
    label: 'Impressão',
    render: () => <h1>Impressão</h1>,
  },
  {
    id: 'html',
    label: 'HTML',
    render: () => <h1>HTML</h1>,
  },
  {
    id: 'audio',
    label: 'Áudio',
    render: () => <h1>Áudio</h1>,
  },
  {
    id: 'video',
    label: 'Vídeo',
    render: () => <h1>Vídeo</h1>,
  },
  {
    id: 'accessibility',
    label: 'Acessibilidade',
    render: () => <h1>Acessibilidade</h1>,
  },
]
