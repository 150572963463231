import {
  styled,
  Td as ChakraTd,
  Th as ChakraTh,
  Checkbox as ChakraCheckbox,
} from '@chakra-ui/react'

const Td = styled(ChakraTd, {
  baseStyle: {
    paddingX: 4,
    paddingY: 2,
    fontSize: 'sm',
    fontWeight: '400',
    lineHeight: '1rem',
  },
})

const Th = styled(ChakraTh, {
  baseStyle: {
    paddingX: 4,
    paddingY: 1,
    fontSize: 'xs',
    fontWeight: '700',
    lineHeight: '1rem',
    letterSpacing: '0.0375rem',
  },
})

const Checkbox = styled(ChakraCheckbox, {
  baseStyle: {
    _checked: {
      '& .chakra-checkbox__control': {
        background: 'brand.primary.dark_1',
        borderColor: 'brand.primary.dark_1',
      },
    },
  },
})

export default {
  Td,
  Th,
  Checkbox,
}
