import React, { useEffect } from 'react'

import { Formik } from 'formik'

import { Stack, Text, Flex, Tag, useSteps } from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import Stepper from '@/components/Stepper'

import AssembleTeams from './AssembleTeams'
import EvaluatorsSelection from './EvaluatorsSelection'

const steps = [
  { title: 'Passo 1', description: 'Montar equipes' },
  { title: 'Passo 2', description: 'Distribuir volumes' },
]

const VolumeSizing = () => {
  const { activeStep, goToPrevious, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  useEffect(() => {
    setActiveStep(1)
  }, [])

  return (
    <Stack p={5} boxShadow="md" borderRadius="base" gap={4}>
      <Flex justify="space-between">
        <Text fontWeight="bold" fontSize="xl" color="brand.primary.dark_1">
          Dimensionamento de Volumes
        </Text>
        <Tag
          bg="brand.primary.light_2"
          color="brand.primary.dark_1"
          fontWeight="medium"
        >
          Análise de Atributos
        </Tag>
      </Flex>
      <Formik
        initialValues={{
          name: 'Objeto 01: Obras Didáticas - Impresso e Digital-Interativo',
          code: '0172 P22 02 02 000 000',
          notice: '2023-24',
        }}
        onSubmit={() => {}}
      >
        <Flex gap={4}>
          <Input isDisabled name="name" label="Objeto" flex={2} />
          <Input isDisabled name="code" label="Código do Objeto" flex={1} />
          <Input isDisabled name="notice" label="Edital" flex={1} />
        </Flex>
      </Formik>
      <Stepper
        size="sm"
        steps={steps}
        index={activeStep}
        onLastStep={() => {
          setActiveStep(0)
        }}
      >
        <AssembleTeams goToNext={goToNext} />
        <EvaluatorsSelection goToPrevious={goToPrevious} />
      </Stepper>
    </Stack>
  )
}

export default VolumeSizing
