import { AxiosError } from 'axios'

import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { getAuthGovBrGetUrl } from './services'
import { TGetAuthGovBrGetUrlResponse } from './types'

export const useQueryAuthGovBrGetUrl = (
  options?: UseQueryOptions<TGetAuthGovBrGetUrlResponse, AxiosError<IErrorDTO>>
) => {
  const queryFunction = () => getAuthGovBrGetUrl()

  return useQuery<TGetAuthGovBrGetUrlResponse, AxiosError<IErrorDTO>>(
    useQueryAuthGovBrGetUrl.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryAuthGovBrGetUrl.getFullQueryKey = (): QueryKey => [
  'auth_gov_br_get_url',
]

useQueryAuthGovBrGetUrl.queryKey = ['auth_gov_br_get_url']
