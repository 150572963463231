import http from '@/api/http'

import {
  TGetPermissionsGroupsParams,
  TGetPermissionsGroupsResponse,
  TGetPermissionsGroupResponse,
  TGetPermissionsGroupParams,
  TCreatePermissionsGroupResponse,
  TCreatePermissionsGroupParams,
  TGetPermissionsResponse,
  TUpdatePermissionsGroupParams,
  TUpdatePermissionsGroupResponse,
} from './types'

export const getPermissionsGroups = async ({
  page = 1,
  pageSize = 10,
}: TGetPermissionsGroupsParams) => {
  const response = await http.get<TGetPermissionsGroupsResponse>(
    `/users/profile/?page=${page}&page_size=${pageSize}`
  )

  return response.data
}

export const getPermissionsGroup = async ({
  id,
}: TGetPermissionsGroupParams) => {
  const response = await http.get<TGetPermissionsGroupResponse>(
    `/users/profile/${id}/`
  )

  return response.data
}

export const postPermissionsGroup = async (
  data: TCreatePermissionsGroupParams
) => {
  const response = await http.post<TCreatePermissionsGroupResponse>(
    '/users/profile/',
    data
  )

  return response.data
}

export const updatePermissionsGroup = async (
  payload: TUpdatePermissionsGroupParams
) => {
  const { id, ...data } = payload

  const response = await http.put<TUpdatePermissionsGroupResponse>(
    `/users/profile/${id}/`,
    data
  )

  return response.data
}

export const getPermissions = async () => {
  const response = await http.get<TGetPermissionsResponse>('/users/permission/')

  return response.data
}
