import React, { createContext, useContext } from 'react'

const ProfileContext = createContext({} as any)

export const ProfileProvider = ({
  value,
  children,
}: {
  value: any
  children: React.ReactNode
}) => {
  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  )
}

export const useProfileContext = () => {
  return useContext(ProfileContext)
}
