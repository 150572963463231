import React from 'react'

import { Formik, Form } from 'formik'
import * as yup from 'yup'

import { Stack, Flex, Button, ModalProps } from '@chakra-ui/react'

import { Input } from '@/components/Forms'
import Modal from '@/components/Modal'

interface IFormValues {
  num_evaluators: string
}

const NumEvaluatorsModal = ({
  isOpen,
  onClose,
}: Omit<ModalProps, 'children'>) => {
  const handleSubmit = (values: IFormValues) => {
    // TODO: implement submit
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      title="Deseja alterar a quantidade de avaliadores no dimensionamento de equipes"
    >
      <Formik
        initialValues={{
          num_evaluators: '',
        }}
        validationSchema={yup.object().shape({
          num_evaluators: yup.number().required('Campo obrigatório'),
        })}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack gap={6}>
            <Input
              label="QUANTIDADE DE AVALIADORES"
              name="num_evaluators"
              type="number"
            />
            <Flex justify="flex-end" gap={4}>
              <Button
                variant="outline"
                borderRadius="full"
                borderWidth="2px"
                borderColor="brand.neutral.light_1"
                color="brand.primary.dark_1"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                borderRadius="full"
                bg="brand.primary.dark_1"
                color="white"
                _hover={{ bg: 'brand.primary.dark_2' }}
              >
                Confirmar
              </Button>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Modal>
  )
}

export default NumEvaluatorsModal
