import React, { useState, useEffect } from 'react'
import { BsCircleHalf } from 'react-icons/bs'
import { Navigate, Outlet } from 'react-router-dom'

import {
  Stack,
  HStack,
  Button,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react'

import { getAuthIsAuthenticated } from '@/api/auth/isAuthenticated/services'
import LayoutLoading from '@/common/Layouts/LayoutLoading'
import { sidebarProps, useBreadcrumbProps } from '@/data/template'
import { stringToBoolean } from '@/utils/string'

import Breadcrumbs from './parts/Breadcrumbs'
import Sidebar from './parts/Sidebar'

function TemplatePage() {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  const { toggleColorMode } = useColorMode()
  const breadcrumbProps = useBreadcrumbProps()

  const darkModeButtonTextColor = useColorModeValue(
    'brand.primary.dark_1',
    'white'
  )
  const darkModeButtonHoverBgColor = useColorModeValue(
    'brand.primary.light_2',
    'brand.primary.dark_1'
  )
  const darkModeButtonActiveBgColor = useColorModeValue(
    'brand.primary.light_1',
    'brand.primary.dark_2'
  )

  useEffect(() => {
    async function checkAuthentication() {
      getAuthIsAuthenticated()
        .then(() => {
          setIsAuthenticated(true)
        })
        .catch(() => {
          setIsAuthenticated(false)
        })
        .finally(() => {
          setIsAuthenticating(false)
        })
    }

    if (!stringToBoolean(process.env.REACT_APP_DISABLE_AUTH)) {
      checkAuthentication()
    } else {
      setIsAuthenticated(true)
      setIsAuthenticating(false)
    }
  }, [])

  if (isAuthenticating) {
    return <LayoutLoading />
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />
  }

  return (
    <Sidebar {...sidebarProps}>
      <Stack flexGrow={1} spacing="4">
        <HStack justifyContent="space-between">
          <Breadcrumbs {...breadcrumbProps} />
          <Button
            variant="ghost"
            leftIcon={<BsCircleHalf />}
            size="xs"
            color={darkModeButtonTextColor}
            _hover={{
              backgroundColor: darkModeButtonHoverBgColor,
            }}
            _active={{
              backgroundColor: darkModeButtonActiveBgColor,
            }}
            onClick={toggleColorMode}
          >
            Alto constraste
          </Button>
        </HStack>
        <Outlet />
      </Stack>
    </Sidebar>
  )
}

export default TemplatePage
