import React from 'react'
import { BsList } from 'react-icons/bs'

import { IconButton, Flex, useColorModeValue, Image } from '@chakra-ui/react'

import LogoSARE from '@/assets/img/sare/logo/16px/horizontal/logo-sare-16px-h-b-nd-m-BB.svg'

import { IMobileProps } from '../../types'

const MobileNav = ({ onOpen, sidebarWidth, ...rest }: IMobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: sidebarWidth }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('brand.highlight.dark', 'brand.primary.base')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      gap={4}
      {...rest}
    >
      <IconButton
        variant="ghost"
        onClick={onOpen}
        aria-label="open menu"
        icon={<BsList size={24} />}
        color="white"
      />

      <Image
        height={{ base: '36px', sm: '48px' }}
        src={LogoSARE}
        alt="Logo SARE"
      />
    </Flex>
  )
}

export default MobileNav
