import React, { useState, useEffect } from 'react'
import {
  FaCalendarAlt,
  FaSearch,
  FaDownload,
  FaPrint,
  FaFilter,
} from 'react-icons/fa'
import InputMask from 'react-input-mask'

import { SearchIcon } from '@chakra-ui/icons'
import {
  Grid,
  GridItem,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Flex,
  Button,
  InputRightElement,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react'

import { IDataFilterProps, TFilter } from './types'

export * from './types'

function DataFilter({
  id,
  testId,
  filters,
  canPrint = true,
  canDownload = true,
  onlySearch = false,
  onClickPrint = () => {},
  onClickDownload = () => {},
  onChangeFilter,
}: IDataFilterProps) {
  const [showFilters, setShowFilters] = useState(false)
  const [filtersState, setFiltersState] = useState({} as TFilter)

  const backgroundColor = useColorModeValue('white', 'brand.neutral.dark_1')

  const inputIconColor = useColorModeValue('brand.primary.dark_1', 'white')
  const inputTextColor = useColorModeValue('gray.500', 'white')
  const inputBgColor = useColorModeValue(
    'brand.neutral.light_2',
    'brand.neutral.dark_1'
  )

  const buttonTextColor = useColorModeValue('brand.primary.dark_1', 'white')
  const buttonBorderColor = useColorModeValue('brand.neutral.light_1', 'white')
  const buttonHoverBgColor = 'brand.neutral.light_1'
  const buttonActiveBgColor = 'brand.primary.base'

  const filterButtonTextColor = useColorModeValue('white', 'dark.primary.900')
  const filterButtonBgColor = useColorModeValue(
    'brand.primary.dark_1',
    'brand.primary.dark_1'
  )
  const filterButtonHoverBgColor = useColorModeValue(
    'brand.primary.dark_2',
    'brand.primary.dark_2'
  )
  const filterButtonActiveBgColor = useColorModeValue(
    'brand.primary.base',
    'brand.primary.base'
  )

  const handleChangeShowFilters = () => {
    setShowFilters(!showFilters)
  }

  useEffect(() => {
    const customFilters = filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.name]: '',
      }
    }, {})

    setFiltersState(prevState => ({
      ...prevState,
      ...customFilters,
    }))
  }, [filters])

  useEffect(() => {
    onChangeFilter(filtersState)
  }, [filtersState])

  return (
    <Flex
      id={id}
      data-testid={testId}
      direction="column"
      gap="2"
      width="100%"
      backgroundColor={backgroundColor}
    >
      <Flex
        gap="2"
        flexDirection={{
          base: 'column',
          xl: 'row',
        }}
        justifyContent="space-between"
      >
        <InputGroup w="full" bg="white" borderRadius="5px">
          <InputRightElement pointerEvents="none">
            <Icon as={FaSearch} color="brand.primary.dark_1" />
          </InputRightElement>
          <Input
            id={id && `${id}-search`}
            data-testid={testId && `${testId}-search`}
            variant="filled"
            placeholder="Pesquisa"
            color={inputTextColor}
            _placeholder={{
              color: inputTextColor,
              fontStyle: 'italic',
            }}
            backgroundColor={inputBgColor}
            value={filtersState.search}
            onChange={event => {
              setFiltersState({
                ...filtersState,
                search: event.target.value,
              })
            }}
          />
        </InputGroup>
        <Flex
          gap="2"
          justifyContent="space-between"
          sx={{
            '@media (min-width: 1200px)': {
              flexDirection: 'row',
            },
            '@media (max-width: 1200px)': {
              flexDirection: 'column',
            },
          }}
        >
          {!onlySearch && (
            <Flex
              sx={{
                '@media (max-width: 500px)': {
                  flexDirection: 'column',
                },
              }}
            >
              <InputGroup width={{ base: 216, lg: 'auto' }}>
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaCalendarAlt} color="brand.neutral.dark_1" />
                </InputLeftElement>
                <Input
                  id={id && `${id}-start-date`}
                  data-testid={testId && `${testId}-start-date`}
                  as={InputMask}
                  mask="99/99/9999"
                  width={{ base: 175, lg: 'auto' }}
                  variant="filled"
                  placeholder="Data de início"
                  color={inputTextColor}
                  _placeholder={{
                    color: inputTextColor,
                    fontStyle: 'italic',
                  }}
                  backgroundColor={inputBgColor}
                  value={filtersState.startDate}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      startDate: event.target.value,
                    })
                  }}
                />
              </InputGroup>
              <InputGroup
                width={{ base: 216, lg: 'auto' }}
                marginLeft={'0.5rem'}
                sx={{
                  '@media (max-width: 500px)': {
                    marginLeft: '0rem',
                    marginTop: '0.5rem',
                  },
                }}
              >
                <InputLeftElement pointerEvents="none">
                  <Icon as={FaCalendarAlt} color="brand.neutral.dark_1" />
                </InputLeftElement>
                <Input
                  id={id && `${id}-end-date`}
                  data-testid={testId && `${testId}-end-date`}
                  as={InputMask}
                  mask="99/99/9999"
                  width={{ base: 175, lg: 'auto' }}
                  variant="filled"
                  placeholder="Data de término"
                  color={inputTextColor}
                  _placeholder={{
                    color: inputTextColor,
                    fontStyle: 'italic',
                  }}
                  backgroundColor={inputBgColor}
                  value={filtersState.endDate}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      endDate: event.target.value,
                    })
                  }}
                />
              </InputGroup>
            </Flex>
          )}
          <Flex
            gap="2"
            sx={{
              '@media (max-width: 400px)': {
                flexDirection: 'column',
              },
            }}
          >
            {canDownload && (
              <Button
                id={id && `${id}-download`}
                data-testid={testId && `${testId}-download`}
                variant="outline"
                width={{ base: 119 }}
                leftIcon={<FaDownload />}
                colorScheme="secondary"
                color={buttonTextColor}
                border="2px solid"
                borderColor={buttonBorderColor}
                _hover={{
                  backgroundColor: buttonHoverBgColor,
                }}
                _active={{
                  backgroundColor: buttonActiveBgColor,
                  borderColor: buttonActiveBgColor,
                }}
                onClick={onClickDownload}
              >
                Baixar
              </Button>
            )}
            {canPrint && (
              <Button
                id={id && `${id}-print`}
                data-testid={testId && `${testId}-print`}
                variant="outline"
                width={{ base: 136 }}
                leftIcon={<FaPrint />}
                colorScheme="secondary"
                color={buttonTextColor}
                border="2px solid"
                borderColor={buttonBorderColor}
                _hover={{
                  backgroundColor: buttonHoverBgColor,
                }}
                _active={{
                  backgroundColor: buttonActiveBgColor,
                  borderColor: buttonActiveBgColor,
                }}
                onClick={onClickPrint}
              >
                Imprimir
              </Button>
            )}
            <Button
              id={id && `${id}-filters`}
              data-testid={testId && `${testId}-filters`}
              leftIcon={<FaFilter />}
              width={{ base: 120 }}
              color={filterButtonTextColor}
              backgroundColor={filterButtonBgColor}
              _hover={{
                backgroundColor: filterButtonHoverBgColor,
              }}
              _active={{
                backgroundColor: filterButtonActiveBgColor,
              }}
              onClick={handleChangeShowFilters}
            >
              Filtros
            </Button>
          </Flex>
        </Flex>
      </Flex>

      {showFilters && (
        <Grid
          rowGap="2"
          columnGap="2"
          templateColumns="repeat(auto-fit, minmax(calc(25% - 0.5rem), 1fr))"
        >
          {filters.map(filter => (
            <GridItem key={`filter-${filter.name}`}>
              {filter.type === 'text' && (
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    {filter.icon || (
                      <Icon as={FaSearch} color={inputIconColor} />
                    )}
                  </InputLeftElement>
                  <Input
                    id={id && `${id}-${filter.name}`}
                    data-testid={testId && `${testId}-${filter.name}`}
                    placeholder={filter.label}
                    color={inputTextColor}
                    _placeholder={{
                      color: inputTextColor,
                    }}
                    backgroundColor={inputBgColor}
                    value={
                      filtersState[filter.name as keyof typeof filtersState]
                    }
                    onChange={event => {
                      setFiltersState({
                        ...filtersState,
                        [filter.name]: event.target.value,
                      })
                    }}
                  />
                </InputGroup>
              )}
              {filter.type === 'select' && (
                <Select
                  id={id && `${id}-${filter.name}`}
                  data-testid={testId && `${testId}-${filter.name}`}
                  placeholder={filter.label}
                  value={filtersState[filter.name as keyof typeof filtersState]}
                  onChange={event => {
                    setFiltersState({
                      ...filtersState,
                      [filter.name]: event.target.value,
                    })
                  }}
                >
                  {filter.options?.map(option => (
                    <option key={`option-${option.value}`} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
              )}
            </GridItem>
          ))}
        </Grid>
      )}
    </Flex>
  )
}

export default DataFilter
