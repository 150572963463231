import React from 'react'
import { FaChevronLeft, FaCheckCircle, FaCalendarAlt } from 'react-icons/fa'
import InputMask from 'react-input-mask'

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik'
import moment from 'moment'
import * as yup from 'yup'

import {
  Button,
  Text,
  Input,
  InputGroup,
  FormControl,
  InputRightElement,
  useToast,
} from '@chakra-ui/react'

import { useMutationRenewalUsers } from '@/api/user/management/mutations'
import Modal from '@/components/Modal'
import { formatDate, reverseDate } from '@/utils/date'
import { getErrorDetails } from '@/utils/error'

import S from '../styles'
import { IRelinkModalProps, TRelinkFormValues } from './types'

const RelinkModal = ({
  isOpen,
  onClose,
  users,
  refetchUsers,
}: IRelinkModalProps) => {
  const toast = useToast()
  const { mutate: renewalUsers, isLoading: isRenewalLoading } =
    useMutationRenewalUsers({
      onSuccess: () => {
        toast({
          title: 'Vínculo renovado com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        onClose()
        refetchUsers()
      },
      onError: error => {
        toast({
          title: 'Erro ao renovar vínculo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const validateSchema = yup.object().shape({
    renewal_date: yup
      .string()
      .required('Este campo é obrigatório')
      .test('test-date', 'Data inválida', value => {
        if (!value) return true
        const isValid = moment(value, 'DD/MM/YYYY', true).isValid()

        return isValid
      })
      .test(
        'test-date-greater-than-today',
        'Data deve ser maior que hoje',
        value => {
          if (!value) return true
          const today = moment()
          const isValid = moment(value, 'DD/MM/YYYY').isAfter(today)

          return isValid
        }
      ),
  })

  const onSubmit = (values: TRelinkFormValues) => {
    const usersToRenew = users.map(
      user => (user as { co_user: number }).co_user
    )

    const payload = {
      co_user: usersToRenew,
      dt_renewal: formatDate(
        reverseDate(values.renewal_date),
        'YYYY-MM-DD HH:mm:ss'
      ),
    }
    renewalUsers(payload)
  }

  return (
    <Modal
      title="Revincular em lote"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Formik
        initialValues={{
          renewal_date: '',
        }}
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <Field name="renewal_date">
            {({ field, form }: FieldProps) => {
              return (
                <FormControl>
                  <InputGroup>
                    <InputRightElement pointerEvents="none">
                      <FaCalendarAlt color="brand.primary.dark_1" />
                    </InputRightElement>
                    <Input
                      as={InputMask}
                      mask="99/99/9999"
                      variant="filled"
                      placeholder="Nova previsão de fim do vínculo"
                      bg="#F8F8F8"
                      value={field.value}
                      onChange={e =>
                        form.setFieldValue(field.name, e.target.value)
                      }
                    />
                  </InputGroup>
                  <ErrorMessage name="renewal_date">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
              )
            }}
          </Field>
          <Text fontSize="xs">
            Você tem certeza de que deseja renovar este vínculo?
          </Text>
          <S.ModalActionButtons>
            <Button
              key="go-back"
              variant="ghost"
              leftIcon={<FaChevronLeft />}
              color="brand.primary.dark_1"
              size="sm"
              onClick={onClose}
              isDisabled={isRenewalLoading}
            >
              Voltar
            </Button>
            <Button
              type="submit"
              key="confirm"
              variant="ghost"
              leftIcon={<FaCheckCircle />}
              bg="brand.primary.dark_1"
              color="white"
              _hover={{
                bg: 'brand.primary.dark_2',
              }}
              size="sm"
              isLoading={isRenewalLoading}
            >
              Sim, desejo renovar
            </Button>
          </S.ModalActionButtons>
        </Form>
      </Formik>
    </Modal>
  )
}

export default RelinkModal
