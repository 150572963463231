import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { IErrorDTO } from '@/DTOs/errorDTO'

import { postAuthLocalLogin } from './services'
import { TPostAuthLocalLoginParams, TPostAuthLocalLoginResponse } from './types'

export const useMutationPostAuthLocalLogin = (
  options?: UseMutationOptions<
    TPostAuthLocalLoginResponse,
    AxiosError<IErrorDTO>,
    TPostAuthLocalLoginParams
  >
) => {
  return useMutation(postAuthLocalLogin, options)
}
