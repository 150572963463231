import { status } from '@/components/Tag/StatusUserSystem/types'

export interface IUser {
  name?: string
  cpf: string
  email: string
  status_code?: string
  ncoProfile?: number[]
}

export interface IUserDetailsProps {
  userId: string
}

export const IUserStatusMapping: Record<string, status | 'default'> = {
  Ativo: 'ATIVO',
  Desvinculado: 'DESVINCULADO',
  Disponível: 'DISPONIVEL',
  'Pré-registro': 'PRE_CADASTRO',
}
