import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FaChevronLeft, FaPlusCircle } from 'react-icons/fa'
import InputMask from 'react-input-mask'

import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik'
import * as yup from 'yup'

import {
  Button,
  Text,
  Input,
  InputGroup,
  Box,
  FormLabel,
  FormControl,
  useSteps,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react'

import { useMutationPreRegistration } from '@/api/user/management/mutations'
import CSVModel from '@/assets/csv/modelo_cadastro_bloco.csv'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import FileUpload from '@/components/FileUpload'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import Stepper from '@/components/Stepper'
import { IStep } from '@/components/Stepper/types'
import { getErrorDetails } from '@/utils/error'
import { parseCSV } from '@/utils/parser'

import { IUser } from '../../types'
import S from '../styles'
import {
  IPreRegistrationModalProps,
  TUniqueUserFormValues,
  TBlockUserFormValues,
} from './types'

const stepDescription: Record<string, string> = {
  unique: 'Input dos dados',
  block: 'Envio de pré-cadastros em bloco',
}

const PreRegistrationModal = ({
  isOpen,
  onClose,
  refetchUsers,
}: IPreRegistrationModalProps) => {
  const toast = useToast()
  const [page, setPage] = useState(0)
  const [preRegistrationType, setPreRegistrationType] = useState('unique')
  const [selectedUsersToRegister, setSelectedUsersToRegister] = useState<
    Array<IUser>
  >([])
  const [uniqueInitialValues, setUniqueInitialValues] =
    useState<TUniqueUserFormValues>({
      cpf: '',
      email: '',
    })
  const [users, setUsers] = useState<Array<Record<string, string>>>([])
  const pageInfo = useMemo(() => {
    const itemsPerPage = 3

    return {
      itemsPerPage,
      pageCount: Math.ceil(users.length / itemsPerPage),
      itemsCount: users.length,
    }
  }, [users])

  const usersToRegisterPaginated = useMemo(() => {
    const start = page * pageInfo.itemsPerPage
    const end = start + pageInfo.itemsPerPage

    return users.slice(start, end)
  }, [page, users])

  const steps: Array<IStep> = [
    {
      title: 'Passo 1',
      description: 'Escolha do envio',
    },
    {
      title: 'Passo 2',
      description: stepDescription[preRegistrationType],
    },
    {
      title: 'Passo 3',
      description: 'Finalização',
    },
  ]

  const { activeStep, goToPrevious, goToNext, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  })

  const { mutate: postUserInBatch, isLoading: isPostLoading } =
    useMutationPreRegistration({
      onSuccess: () => {
        toast({
          title: 'Usuário(s) pré-cadastrado(s) com sucesso!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        if (preRegistrationType === 'block') {
          setUsers([])
          goToNext()
        }
        onClose()
        refetchUsers()
      },
      onError: () => {
        toast({
          title: 'Erro ao pré-cadastrar usuários',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      },
    })

  const hasSelectedUsers = () => {
    if (!selectedUsersToRegister.length) {
      toast({
        title: 'Nenhum usuário selecionado',
        description: 'Selecione pelo menos um usuário',
        status: 'warning',
        duration: 1000,
        isClosable: true,
      })
      return false
    }

    return true
  }

  const usersTableHeaders: Array<IHeader> = [
    {
      name: 'CPF',
      key: 'cpf',
    },
    {
      name: 'EMAIL',
      key: 'email',
    },
  ]

  const validateSchemaUniqueForm = yup.object().shape({
    cpf: yup
      .string()
      .required('Este campo é obrigatório')
      .test('test-cpf', 'CPF inválido', value => {
        const isValid = !value.includes('_')

        return isValid
      }),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('Este campo é obrigatório')
      .test('test-email-domain', 'Domínio inválido', value => {
        const isValid = value.endsWith('@nees.ufal.br')

        return isValid
      }),
  })

  const validateSchemaBlockForm = yup.object().shape({
    file: yup
      .mixed()
      .required('Este campo é obrigatório')
      .test('test-file-type', 'Formato de arquivo inválido', value => {
        if (!value) return true
        const isValid = (value as File).type === 'text/csv'

        return isValid
      }),
  })

  const handleDownloadCSVModel = () => {
    const downloadLink = document.createElement('a')
    downloadLink.href = CSVModel
    downloadLink.download = 'Modelo cadastro em bloco.csv'
    downloadLink.click()
  }

  const onSubmitUniqueUser = (values: TUniqueUserFormValues) => {
    setUniqueInitialValues(values)
    handleFinishRegistration([values])
  }

  const onSubmitBlockUser = async (values: TBlockUserFormValues) => {
    const { file } = values

    await parseCSV({
      file: file as File,
      requiredColumns: ['cpf', 'email'],
    })
      .then(parsedData => {
        setUsers(parsedData)
        goToNext()
      })
      .catch(error => {
        toast({
          title: 'Erro ao processar arquivo',
          description: getErrorDetails(error),
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      })
  }

  const handleFinishRegistration = (users: Array<IUser>) => {
    const usersToRegister = users.map(user => ({
      dsCpf: user.cpf.replace(/\D/g, ''),
      dsGovbrEmail: user.email,
      ncoProfile: user.ncoProfile || [],
    }))

    postUserInBatch(usersToRegister)
  }

  const UniqueRegistrationForm = () => {
    return (
      <Formik
        initialValues={uniqueInitialValues}
        validationSchema={validateSchemaUniqueForm}
        onSubmit={onSubmitUniqueUser}
      >
        <Form>
          <Stack gap={4}>
            <VStack gap={4}>
              <Box w="full">
                <FormLabel fontSize="sm">CPF</FormLabel>
                <Field name="cpf">
                  {({ field, form }: FieldProps) => {
                    return (
                      <FormControl>
                        <InputGroup>
                          <Input
                            as={InputMask}
                            mask="999.999.999-99"
                            variant="filled"
                            placeholder="Placeholder"
                            value={field.value}
                            onChange={e =>
                              form.setFieldValue(field.name, e.target.value)
                            }
                          />
                        </InputGroup>
                        <ErrorMessage name="cpf">
                          {(message: string) => (
                            <Text color="red.600" fontSize="sm">
                              {message}
                            </Text>
                          )}
                        </ErrorMessage>
                      </FormControl>
                    )
                  }}
                </Field>
              </Box>
              <Box w="full">
                <FormLabel fontSize="sm">E-mail institucional</FormLabel>
                <Field
                  as={Input}
                  type="email"
                  name="email"
                  placeholder="Placeholder"
                  variant="filled"
                />
                <ErrorMessage name="email">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </Box>
            </VStack>
            <S.ModalActionButtons>
              <Button
                key="go-back"
                variant="ghost"
                size="sm"
                leftIcon={<FaChevronLeft />}
                color="brand.primary.dark_1"
                isDisabled={isPostLoading}
                onClick={goToPrevious}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                key="confirm"
                size="sm"
                leftIcon={<FaPlusCircle />}
                bg="brand.primary.dark_1"
                color="white"
                _hover={{
                  bg: 'brand.primary.dark_2',
                }}
                isDisabled={isPostLoading}
                isLoading={isPostLoading}
              >
                Adicionar usuário
              </Button>
            </S.ModalActionButtons>
          </Stack>
        </Form>
      </Formik>
    )
  }

  const BatchRegistrationForm = () => {
    return (
      <Stack gap="4">
        <Stack>
          <Text fontSize="xs">
            Para reconhecermos o arquivo enviado deve está salvo em .csv e
            seguir o padrão do arquivo modelo do SARE. Para evitar erros de
            compatibilidade do arquivo, baixe o modelo e adeque seu arquivo
            antes do envio.
          </Text>
          <Box>
            <Button
              size="sm"
              fontSize="xs"
              borderWidth="2px"
              borderColor="brand.neutral.light_1"
              color="brand.neutral.dark_2"
              bg="white"
              onClick={handleDownloadCSVModel}
            >
              Baixar modelo de csv
            </Button>
          </Box>
        </Stack>
        <Formik
          initialValues={{
            file: null,
          }}
          validationSchema={validateSchemaBlockForm}
          onSubmit={onSubmitBlockUser}
        >
          <Form>
            <Stack gap="4">
              <FormControl>
                <FormLabel fontSize="sm">Selecione arquivo</FormLabel>
                <FileUpload
                  name="file"
                  maxFileSize={15}
                  helperText="Só aceitamos arquivos .csv"
                  acceptedFileTypes={['.csv']}
                />
                <ErrorMessage name="file">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
              <S.ModalActionButtons>
                <Button
                  key="go-back"
                  variant="ghost"
                  size="sm"
                  leftIcon={<FaChevronLeft />}
                  color="brand.primary.dark_1"
                  onClick={goToPrevious}
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  key="confirm"
                  size="sm"
                  bg="brand.primary.dark_1"
                  color="white"
                  _hover={{
                    bg: 'brand.primary.dark_2',
                  }}
                >
                  Confirmar
                </Button>
              </S.ModalActionButtons>
            </Stack>
          </Form>
        </Formik>
      </Stack>
    )
  }

  return (
    <Modal
      title="Pré-cadastro de usuários"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
    >
      <Stepper
        size="sm"
        steps={steps}
        index={activeStep}
        onLastStep={() => {
          setActiveStep(0)
        }}
      >
        <Stack gap="4">
          <Text fontSize="sm">
            No processo de pré-cadastramento de usuários, oferecemos duas
            opções: opções: &apos;pré-cadastro em bloco&apos; para maior
            eficiência ao realizar o upload de um arquivo com os dados de vários
            usuários e &apos;pré-cadastro único&apos; para maior flexibilidade
            ao preencher os detalhes de um formulário. Escolha a opção que mais
            se adequa às suas necessidades.
          </Text>
          <S.ModalActionButtons>
            <Button
              key="go-back"
              variant="outline"
              size="sm"
              border="2px solid"
              borderColor="brand.neutral.light_1"
              color="brand.primary.dark_1"
              onClick={() => {
                setPreRegistrationType('unique')
                goToNext()
              }}
            >
              Envio único
            </Button>
            <Button
              key="confirm"
              size="sm"
              bg="brand.primary.dark_1"
              color="white"
              _hover={{
                bg: 'brand.primary.dark_2',
              }}
              onClick={() => {
                setPreRegistrationType('block')
                goToNext()
              }}
            >
              Envio em bloco
            </Button>
          </S.ModalActionButtons>
        </Stack>
        <Box>
          {preRegistrationType === 'unique' ? (
            <UniqueRegistrationForm />
          ) : (
            <BatchRegistrationForm />
          )}
        </Box>
        <Stack gap="4">
          <Stack gap="4">
            <DataTable
              rowId="cpf"
              headers={usersTableHeaders}
              data={usersToRegisterPaginated}
              selectableRow
              onRowSelectionChange={values =>
                setSelectedUsersToRegister(values as Array<IUser>)
              }
            />
            <Text
              fontSize="xs"
              fontWeight="bold"
              color="gray.600"
              textTransform="uppercase"
              textAlign="end"
            >
              Total de usuários: {users.length}
            </Text>
          </Stack>
          <Pagination
            itemsPerPage={pageInfo.itemsPerPage}
            pageCount={pageInfo.pageCount}
            itemsCount={pageInfo.itemsCount}
            onChangePageInfo={({ pageIndex }) => setPage(pageIndex)}
          />
          <S.ModalActionButtons>
            <Button
              key="go-back"
              variant="ghost"
              size="sm"
              leftIcon={<FaChevronLeft />}
              color="brand.primary.dark_1"
              isDisabled={isPostLoading}
              onClick={goToPrevious}
            >
              Voltar
            </Button>
            <Button
              key="confirm"
              size="sm"
              leftIcon={<FaPlusCircle />}
              bg="brand.primary.dark_1"
              color="white"
              _hover={{
                bg: 'brand.primary.dark_2',
              }}
              isLoading={isPostLoading}
              onClick={() => {
                if (hasSelectedUsers()) {
                  handleFinishRegistration(selectedUsersToRegister)
                }
              }}
            >
              Adicionar usuários
            </Button>
          </S.ModalActionButtons>
        </Stack>
      </Stepper>
    </Modal>
  )
}

export default PreRegistrationModal
