import React from 'react'

import { Stack, CircularProgress } from '@chakra-ui/react'

const LayoutLoading: React.FC = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      backgroundColor="white"
    >
      <CircularProgress size="5rem" thickness="0.25rem" isIndeterminate />
    </Stack>
  )
}

export default LayoutLoading
