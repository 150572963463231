export const exampleData = () => {
  const data = []

  for (let i = 0; i < 20; i++) {
    data.push({
      team: `EQ0${i}`,
      supervisor: `Supervisor ${i}`,
      permission: i % 2 === 0 ? 'Analista' : 'Revisor',
      status: 'waiting',
    })
  }

  return data
}
