import React from 'react'

import { Tag } from '@chakra-ui/react'

import { IStatusObjectTagProps, IStatusObjectTagStyles } from './types'

const styles: IStatusObjectTagStyles = {
  active: {
    name: 'Ativo',
    color: '#168821',
    backgroundColor: '#E3F5E1',
  },
  inactive: {
    name: 'Inativo',
    color: '#333333',
    backgroundColor: '#F8F8F8',
  },
  finished_red: {
    name: 'Finalizado',
    color: '#E60000',
    backgroundColor: '#FFF3F2',
  },
  finished_green: {
    name: 'Finalizado',
    color: '#168821',
    backgroundColor: '#E3F5E1',
  },
  ongoing: {
    name: 'Em andamento',
    color: '#475671',
    backgroundColor: '#DDE1E5',
  },
  waiting: {
    name: 'Em espera',
    color: '#333333',
    backgroundColor: '#F8F8F8',
  },
  waiting_bond: {
    name: 'Aguardando vínculo',
    color: 'brand.primary.dark_1',
    backgroundColor: '#EDF5FF',
  },
  external_phase: {
    name: 'Fase Externa',
    color: '#DDA253',
    backgroundColor: '#FFF3F2',
  },
  not_started: {
    name: 'Não iniciado',
    color: '#333333',
    backgroundColor: '#F8F8F8',
  },
  default: {
    name: 'default',
    color: '#333333',
    backgroundColor: '#F8F8F8',
  },
}

function StatusTag({ tag, ...rest }: IStatusObjectTagProps) {
  const { name, backgroundColor, color } = styles[tag] || styles.default

  return (
    <Tag bg={backgroundColor} color={color ? color : 'white'} {...rest}>
      {name}
    </Tag>
  )
}

export default StatusTag
