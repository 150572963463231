import React from 'react'
import { Outlet } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

const SystemSettings = () => {
  return (
    <Flex direction="column" gap="6">
      <Outlet />
    </Flex>
  )
}

export default SystemSettings
