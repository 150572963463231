import http from '@/api/http'

import { TGetAuthIsAuthenticatedResponse } from './types'

export const getAuthIsAuthenticated = async () => {
  const response = await http.get<TGetAuthIsAuthenticatedResponse>(
    '/auth/is_authenticated/'
  )

  return response.data
}
