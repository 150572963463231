export const exampleData = () => {
  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      volumeCode: `Volume ${i}1234 5678 8910 1112`,
      phase1: 'finished_green',
      phase3: 'ongoing',
      phase4: 'not_started',
      phase5: 'not_started',
    })
  }

  return data
}
