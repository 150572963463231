import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Box,
  FormLabel,
  Flex,
  Input,
  Text,
  Textarea,
} from '@chakra-ui/react'

import Stepper from '@/components/Stepper'
import Tabs from '@/components/Tabs'
import Tag from '@/components/Tag'

import { steps, tabs } from './controller'

const ObjectInfo = () => {
  return (
    <Flex
      direction="column"
      gap="4"
      pt="5"
      px="5"
      boxShadow="md"
      borderRadius="md"
    >
      <Flex justify="space-between">
        <Text
          color="#475671"
          fontSize="20px"
          fontWeight="700"
          lineHeight="120%"
        >
          Objeto 01: Obras Didáticas - Impresso e Digital-Interativo
        </Text>
        <Tag group="statusObjects" value="active" />
      </Flex>

      <Box w="full" h="full" gap="4">
        <Flex direction="row" gap="4">
          <Box w="50%">
            <FormLabel fontSize="sm">Edital</FormLabel>
            <Input
              placeholder="Edital"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
          <Box w="50%">
            <FormLabel fontSize="sm">Código do Objeto</FormLabel>
            <Input
              placeholder="0172 P22 02 02 000 000"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
            />
          </Box>
        </Flex>

        <Flex direction="row">
          <Box w="100%">
            <FormLabel fontSize="sm">Detalhamento do Objeto</FormLabel>
            <Textarea
              placeholder="Detalhamento do Objeto"
              disabled
              bg="brand.neutral.light_2"
              borderColor="brand.neutral.light_2"
              _placeholder={{ fontStyle: 'italic' }}
              rows={2}
            />
          </Box>
        </Flex>
      </Box>
      <Stepper steps={steps} index={2}>
        {steps.map((step, index) => (
          <div key={index}></div>
        ))}
      </Stepper>
    </Flex>
  )
}

const ObjectPhase = () => {
  const [activeTabId, setActiveTabId] = useState('permissoes')

  return (
    <Flex direction="column" gap="4" p="5" boxShadow="md" borderRadius="md">
      <Tabs
        items={tabs}
        initialTab={activeTabId}
        onTabClick={tabInfo => setActiveTabId(tabInfo.id)}
      />
    </Flex>
  )
}

const DetailObjects = () => {
  const { coObject } = useParams()

  return (
    <Flex direction="column" gap="6">
      <ObjectInfo />
      <ObjectPhase />
    </Flex>
  )
}

export default DetailObjects
