import { IFilter } from '@/components/DataFilter'

import { IFailsList } from '../types'

const loremIpsum = `Mussum Ipsum, cacilds Vidis litro abertis. Näo sou faixa
                      preta cumpadi, sou preto inteiris, inteiris. A ordem dos
                      tratores näo altera o päo duris. Nec orci ornare
                      consequat. Praesent lacinia ultrices consectetur.`

export const filtersFailsGroup: Array<IFilter> = [
  {
    name: 'filter1',
    label: 'Filtro 1',
    type: 'select',
    options: [
      { label: 'Option 1', value: 'option1' },
      { label: 'Option 2', value: 'option2' },
    ],
  },
  {
    name: 'filter2',
    label: 'Filtro 2',
    type: 'select',
    options: [
      { label: 'Option 1', value: 'option1' },
      { label: 'Option 2', value: 'option2' },
    ],
  },
]

export const failsList: Array<IFailsList> = [
  {
    title: 'Título da falha',
    description: loremIpsum,
    code: '---',
  },
  {
    title: 'Título da falha',
    description: loremIpsum,
    code: '---',
  },
  {
    title: 'Título da falha',
    description: loremIpsum,
    code: '---',
  },
  {
    title: 'Título da falha',
    description: loremIpsum,
    code: '---',
  },
  {
    title: 'Título da falha',
    description: loremIpsum,
    code: '---',
  },
]
