import React, { useEffect } from 'react'

import {
  Stepper as ChakraStepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepSeparator,
  Box,
  Text,
  Stack,
  Flex,
} from '@chakra-ui/react'

import { IStepperProps } from './types'

const Stepper = ({ steps, onLastStep, children, ...rest }: IStepperProps) => {
  const { index: activeStep } = { ...rest }
  const activeStepDescription = steps[activeStep]?.description
  const activeStepTitle = steps[activeStep]?.title

  useEffect(() => {
    if (activeStep === steps.length) {
      onLastStep?.()
    }
  }, [activeStep])

  return (
    <Flex flexGrow={1}>
      <Stack gap="4" width="100%">
        <Box py="4">
          <ChakraStepper {...rest} gap="0">
            {steps.map((_step, index) => (
              <Step as={Box} key={`step-${index}`} gap="0">
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} />
                </StepIndicator>
                <StepSeparator as={Box} _horizontal={{ ml: '0' }} />
              </Step>
            ))}
          </ChakraStepper>
          <Flex justify="space-between">
            {steps.map((_step, index) => (
              <Text
                key={`text-${index}`}
                fontSize="sm"
                mt="1"
                color="brand.primary.dark_1"
                fontWeight="semibold"
              >
                {_step.title}:{' '}
                <Text
                  as="span"
                  fontSize="xs"
                  fontWeight="normal"
                  color="gray.500"
                >
                  {_step.description}
                </Text>
              </Text>
            ))}
          </Flex>
        </Box>
        {React.Children.map(children, (child, index) => {
          if (
            index === activeStep ||
            (activeStep === steps.length && index === steps.length)
          ) {
            return (
              <Box h="full" w="full" key={index} overflow="auto">
                {child}
              </Box>
            )
          }
          return null
        })}
      </Stack>
    </Flex>
  )
}

export default Stepper
