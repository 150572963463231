import React from 'react'

import { Box, Stack, Flex } from '@chakra-ui/react'

import { Input, Select } from '@/components/Forms'
import { useProfileContext } from '@/contexts/ProfileContext/ProfileContext'

import S from '../styles'

const roleJobOptions = [
  { value: 'Discente', label: 'Discente' },
  { value: 'Docente', label: 'Docente' },
  { value: 'Técnico Administrativo', label: 'Técnico Administrativo' },
  { value: 'Outros', label: 'Outros' },
]

const PersonalLink: React.FC = () => {
  const { isLoadingUserData } = useProfileContext()

  return (
    <Box width="100%">
      <Stack gap={12}>
        <Stack gap={4}>
          <S.HeaderText>Dados da Instituição</S.HeaderText>
          <Flex gap={4}>
            <Flex flex={3}>
              <Input
                label="Instituição"
                name="dsInstitution"
                placeholder="Instituição"
                helperText="Obrigatório"
                isLoading={isLoadingUserData}
              />
            </Flex>
            <Flex flex={1}>
              <Select
                options={roleJobOptions}
                label="Cargo"
                name="dsRolePositionJob"
                placeholder="Cargo"
                helperText="Obrigatório"
                isLoading={isLoadingUserData}
              />
            </Flex>
          </Flex>
        </Stack>
        <Stack gap={4}>
          <S.HeaderText>Dados de Vínculo</S.HeaderText>
          <Flex gap={4}>
            <Flex flex={1}>
              <Input
                label="Data de inicio do vínculo"
                name="dtJobBindStart"
                type="date"
                placeholder="Início do vínculo"
                helperText="Obrigatório"
                isDisabled
                isLoading={isLoadingUserData}
              />
            </Flex>
            <Flex flex={1}>
              <Input
                label="Previsão de fim do vínculo"
                name="dtJobBindEnd"
                type="date"
                placeholder="Fim do vínculo"
                helperText="Obrigatório"
                isDisabled
                isLoading={isLoadingUserData}
              />
            </Flex>
          </Flex>
        </Stack>
      </Stack>
    </Box>
  )
}

export default PersonalLink
